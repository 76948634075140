import { useState } from 'react'
import { toast } from 'react-toastify'
import { useLocation } from 'wouter'

// Hook personalizado `useFetch` para realizar peticiones API y manejar su estado
export default function useFetch() {
  const [, setLocation] = useLocation()

  // useState():
  const [loading, setLoading] = useState(false)
  const [isCalled, setIsCalled] = useState(false)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [message, setMessage] = useState(null)

  async function fetchAction(params) {
    // Reinicia los estados antes de realizar la nueva solicitud
    setIsCalled(true)
    setData(null)
    setError(null)
    setMessage(null)
    setLoading(true)

    // Crecendiales API:
    const apiCredentials = `Basic ${btoa(
      process.env.REACT_APP_API_USERNAME +
        ':' +
        process.env.REACT_APP_API_PASSWORD
    )}`

    try {
      // Cabeceras:
      const headers = params.formData
        ? { Accept: 'multipart/form-data', Authorization: apiCredentials }
        : { 'Content-Type': 'application/json', Authorization: apiCredentials }

      // Cuerpo:
      const body = params.formData
        ? params.formData
        : JSON.stringify(params.body)

      // Realiza la solicitud `fetch` a la API
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/${params.url}`,
        {
          method: params.method,
          headers,
          body
        }
      )

      if (response?.ok) {
        const responseData = await response?.json()
        setData(responseData)
        setMessage(responseData?.message)
      }

      if (!response?.ok) {
        if (
          (response?.status === 401 && location?.pathname !== '/login') ||
          response?.status === 403
        ) {
          setLocation('/logout')

          // Función para mostrar un mensaje de error
          toast.error('Sessión caducada', {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
          })
        } else if (response?.status === 404) {
          setError('Datos no encontrados')
        } else {
          const errorData = await response?.json()
          setError(errorData?.error || 'Error servidor')
          console.log('useFetch() - error: ', errorData)
        }
      }
    } catch (error) {
      console.log('useFetch() - catch:', error)
      setError(error) // Si ocurre un error durante la solicitud, se almacena el error
    } finally {
      setLoading(false) // Independientemente del resultado, se indica que la solicitud ha terminado
    }
  }

  return [
    {
      loading,
      isCalled,
      data,
      error,
      message
    },
    fetchAction
  ]
}

import DeleteModal from 'components/ui/Modals/DeleteModal'
import { useModal } from './ModalContext'

// Objeto que asocia nombres de modal con sus componentes correspondientes
const ModalLookUp = {
  DeleteModal // Asocia el nombre "DeleteModal" con el componente DeleteModal
}

export default function ModalManager() {
  // Obtiene el estado actual del modal y la función para cerrarlo desde el contexto
  const { modal, closeModal } = useModal()

  // Si no hay un modal abierto, no renderiza nada.
  if (!modal) return null

  // Obtiene el componente del modal según su nombre
  const Modal = ModalLookUp[modal.name]

  // Renderiza el componente del modal pasando la función para cerrarlo y las propiedades del modal
  return <Modal onClose={closeModal} {...modal.props} />
}

import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { FaLongArrowAltDown, FaLongArrowAltUp } from 'react-icons/fa'
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from 'react-table'
import TableBar from './TableBar'

export default function Table({
  tableKey,
  tableName,
  columns,
  data,
  defaultSort,
  handleClick
}) {
  // Filtro personalizado para rango de fechas. Código basado en este ejemplo: https://github.com/TanStack/table/discussions/2453
  function dateBetweenFilterFn(rows, id, filterValues) {
    let startDate = null
    let endDate = null

    // Convertir fecha de inicio en un objeto 'Date'
    if (
      typeof filterValues[0] === 'string' &&
      filterValues[0] !== '' &&
      filterValues[0] !== null &&
      filterValues[0] !== undefined
    ) {
      const parts = filterValues[0].split('-')

      if (parts.length === 3) {
        let [year, month, day] = parts.map((num) => parseInt(num, 10))
        year = year < 100 ? 2000 + year : year
        startDate = new Date(Date.UTC(year, month - 1, day, 0, 0, 0))
      }
    }

    // Convertir fecha de fin en un objeto 'Date'
    if (
      typeof filterValues[1] === 'string' &&
      filterValues[1] !== '' &&
      filterValues[1] !== null &&
      filterValues[1] !== undefined
    ) {
      const parts = filterValues[1].split('-')

      if (parts.length === 3) {
        let [year, month, day] = parts.map((num) => parseInt(num, 10))
        year = year < 100 ? 2000 + year : year
        endDate = new Date(Date.UTC(year, month - 1, day, 0, 0, 0))
      }
    }

    // Filtrar filas por fecha dentro del rango
    return rows.filter((r) => {
      let rowDate = null

      // Convertir fecha de la fila en un objeto 'Date'
      if (
        typeof r.values[id] === 'string' &&
        r.values[id] !== '' &&
        r.values[id] !== null &&
        r.values[id] !== undefined
      ) {
        const parts = r.values[id].split('/')

        if (parts.length === 3) {
          let [day, month, year] = parts.map((num) => parseInt(num, 10))
          year = year < 100 ? 2000 + year : year
          rowDate = new Date(Date.UTC(year, month - 1, day, 0, 0, 0))
        }
      }

      // Filtrar según las fechas de inicio y fin
      if (rowDate !== null) {
        if (filterValues.length === 0 || (!startDate && !endDate)) {
          return true // No se aplican filtros, devolver todas las filas
        } else if (startDate && !endDate) {
          return rowDate >= startDate // Solo fecha de inicio, filtrar por fechas posteriores o iguales
        } else if (!startDate && endDate) {
          return rowDate <= endDate // Solo fecha de fin, filtrar por fechas anteriores o iguales
        } else {
          return rowDate >= startDate && rowDate <= endDate // Ambas fechas existen, filtrar entre ellas
        }
      }

      return false
    })
  }

  dateBetweenFilterFn.autoRemove = (val) => !val

  // Definir tipos de filtros personalizados
  const filterTypes = useMemo(
    () => ({
      dateBetween: dateBetweenFilterFn
    }),
    []
  )

  // Configuración de la tabla con react-table
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Filas de la página actual
    setAllFilters,
    rows, // Filas filtradas
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize } // Información de paginación
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: {
        pageSize: 25, // Número de filas por página
        sortBy: defaultSort ? [defaultSort] : [] // Ordenación predeterminada, si existe
      }
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  )

  return (
    <div className="flex flex-col min-w-full align-middle h-full overflow-y-hidden overflow-x-auto">
      <div className="flex items-center justify-end w-full px-2 pb-2 space-x-2 font-bold text-left">
        <button
          type="button"
          className="h-10 px-4 font-notoBold text-sm text-primary hover:text-secondary transition-colors duration-150 outline-none underline decoration-2 decoration-secondary hover:decoration-primary"
          onClick={() => {
            setAllFilters([])

            columns.forEach((column) => {
              localStorage.setItem(column.accessor, undefined)
            })
          }}
        >
          Borrar
        </button>
      </div>

      {/* Tabla */}
      <div
        id="scrollable-table"
        className="pb-1 md:pb-0 pr-1 md:pr-0.5 grow overflow-auto"
      >
        <table {...getTableProps()} className="min-w-full table-fixed">
          {/* Cabecera */}
          <thead className="bg-secondary sticky top-0 z-20 border-x-2 border-secondary">
            {headerGroups.map((headerGroup, index) => (
              <tr
                key={index}
                className="divide-x divide-dashed divide-secondary"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((header, index) => {
                  // La siguiente línea de código sirve para solucionar el siguiente error
                  //  Warning: A props object containing a "key" prop is being spread into JSX:
                  //    let props = {key: someKey, role: ..., onClick: ..., className: ..., children: ...};
                  //    <tr {...props} />
                  //  React keys must be passed directly to JSX without using spread:
                  //    let props = {role: ..., onClick: ..., className: ..., children: ...};
                  //    <tr key={someKey} {...props} />
                  //  Error Component Stack
                  const { key, ...headerProps } = header.getHeaderProps(
                    header.getSortByToggleProps({
                      title: header.TitleDescription
                    })
                  )

                  return (
                    <th
                      key={key}
                      className="text-white hover:bg-[#009d96] font-notoBlack h-20 min-h-20 p-1"
                      {...headerProps}
                    >
                      <div className="flex flex-col justify-between h-full space-y-2">
                        <div
                          className={`${header?.Filter ? 'flex-row' : 'h-full'} flex w-full justify-center items-start text-sm text-center select-none`}
                        >
                          <span className="overflow-hidden whitespace-nowrap text-ellipsis">
                            {header.render('Header')}
                          </span>

                          <div>
                            {header.isSorted ? (
                              header.isSortedDesc ? (
                                <FaLongArrowAltDown
                                  color={'#ffffff'}
                                  size={15}
                                />
                              ) : (
                                <FaLongArrowAltUp color={'#ffffff'} size={15} />
                              )
                            ) : (
                              ''
                            )}
                          </div>
                        </div>

                        <div>
                          {header.Filter ? header.render('Filter') : null}
                        </div>
                      </div>
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>

          {/* Cuerpo */}
          {rows?.length > 0 ? (
            <tbody
              className="bg-white divide-y divide-gray-200 border-x-2 border-b-2 border-secondary z-0"
              {...getTableBodyProps()}
            >
              {page.map((row, index) => {
                prepareRow(row)

                // La siguiente línea de código sirve para solucionar el siguiente error
                //  Warning: A props object containing a "key" prop is being spread into JSX:
                //    let props = {key: someKey, role: ..., onClick: ..., className: ..., children: ...};
                //    <tr {...props} />
                //  React keys must be passed directly to JSX without using spread:
                //    let props = {role: ..., onClick: ..., className: ..., children: ...};
                //    <tr key={someKey} {...props} />
                //  Error Component Stack
                const { key, ...rowProps } = row.getRowProps()

                return (
                  <>
                    <tr
                      key={key}
                      {...rowProps}
                      onClick={(e) =>
                        handleClick && handleClick(e, row.original)
                      }
                      className={`divide-x divide-dashed divide-secondary
                      ${handleClick && 'hover:bg-[#c6f8f6] cursor-pointer'}
                      ${index % 2 !== 0 && 'bg-[#dddddd]'}`}
                    >
                      {row.cells.map((cell, index) => {
                        return (
                          <td
                            key={index}
                            className="py-2 overflow-hidden text-sm font-noto whitespace-nowrap text-ellipsis"
                          >
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  </>
                )
              })}
            </tbody>
          ) : (
            <tbody
              className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900"
              {...getTableBodyProps()}
            >
              <tr>
                <td
                  className="p-4 text-sm font-medium text-gray-700 whitespace-nowrap"
                  colSpan={columns?.length}
                >
                  No se han encontrado registros
                </td>
              </tr>
            </tbody>
          )}

          {/* Pie de tabla */}
          <tfoot>
            <tr>
              <td colSpan={columns.length} className="p-0 m-0">
                <TableBar
                  tableKey={tableKey}
                  tableName={tableName}
                  rows={rows}
                  previousPage={previousPage}
                  canPreviousPage={canPreviousPage}
                  pageIndex={pageIndex}
                  pageOptions={pageOptions}
                  nextPage={nextPage}
                  canNextPage={canNextPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )
}

Table.propTypes = {
  tableKey: PropTypes.string.isRequired,
  tableName: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  defaultSort: PropTypes.any,
  handleClick: PropTypes.func
}

import OrderCompGrid from 'components/Logistics/OrderCompGrid'
import { useTitle } from 'contexts/TitleContext'
import { useUser } from 'contexts/UserContext'
import useFetch from 'hooks/useFetch'
import { useToast } from 'hooks/useToast'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export default function OrderViewGrid() {
  const { showToast } = useToast()
  const { setTitle } = useTitle()
  const { user } = useUser()
  const { t } = useTranslation()

  // useFetch(): hook personalizado para realizar solicitudes GET, POST, PUT, DELETE
  const [fetchedOrders, fetchOrders] = useFetch()

  // CRUD: GET
  useEffect(() => {
    if (user) {
      const title = {
        name: 'order.title'
      }
      setTitle(title)

      // Asegúrate de que las variables de usuario están disponibles
      const apiCallGet = {
        url: 'client/orders',
        method: 'POST',
        body: {
          AccessToken: user.AccessToken,
          email: user.email,
          password: user.password
        },
        messageKo: '¡Error cargando Clientes!'
      }
      fetchOrders(apiCallGet)
    }
  }, [])

  useEffect(() => {
    if (fetchedOrders?.error) {
      showToast(fetchedOrders?.error, 'error')
    }
  }, [fetchedOrders?.data, fetchedOrders?.error])

  // ############################################################

  // 'handleClick()' para cada item en una lista, al pulsar un item nos llevará a la ruta especificada en el 'setLocation':
  // const handleClick = (e, item) => {
  //   e.preventDefault()
  //   setLocation(
  //     // `/orders/${item.Orders_IdInterno ? item.Orders_IdInterno : 'new'}`
  //     `#`
  //   )
  // }

  // ############################################################

  return (
    <OrderCompGrid
      // items={[
      //   {
      //     Numero_pedido_Cliente: '12345',
      //     lineapedido_numeropedido: '98765',
      //     Posicion: new Intl.NumberFormat('de-DE').format(1),
      //     Referencia_Cliente: 'Ref123',
      //     Referencia_Technoform: 'TF987',
      //     Confeccion: 'Custom',
      //     Material: 'Aluminum',
      //     Metros: new Intl.NumberFormat('de-DE').format(12.5),
      //     Longitud: new Intl.NumberFormat('de-DE').format(300),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(50),
      //     Fecha_Pedido: '2025-03-05',
      //     Fecha_Entrega: '2025-04-05',
      //     Cantidad_Estimada: 100,
      //     Produccion_Extranjera: 'Yes'
      //   },
      //   {
      //     Numero_pedido_Cliente: '23456',
      //     lineapedido_numeropedido: '87654',
      //     Posicion: new Intl.NumberFormat('de-DE').format(2),
      //     Referencia_Cliente: 'Ref234',
      //     Referencia_Technoform: 'TF876',
      //     Confeccion: 'Standard',
      //     Material: 'Steel',
      //     Metros: new Intl.NumberFormat('de-DE').format(15.3),
      //     Longitud: new Intl.NumberFormat('de-DE').format(250),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(60),
      //     Fecha_Pedido: '2025-03-06',
      //     Fecha_Entrega: '2025-04-06',
      //     Cantidad_Estimada: 200,
      //     Produccion_Extranjera: 'No'
      //   },
      //   {
      //     Numero_pedido_Cliente: '34567',
      //     lineapedido_numeropedido: '76543',
      //     Posicion: new Intl.NumberFormat('de-DE').format(3),
      //     Referencia_Cliente: 'Ref345',
      //     Referencia_Technoform: 'TF765',
      //     Confeccion: 'Custom',
      //     Material: 'Plastic',
      //     Metros: new Intl.NumberFormat('de-DE').format(18.7),
      //     Longitud: new Intl.NumberFormat('de-DE').format(320),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(70),
      //     Fecha_Pedido: '2025-03-07',
      //     Fecha_Entrega: '2025-04-07',
      //     Cantidad_Estimada: 150,
      //     Produccion_Extranjera: 'Yes'
      //   },
      //   {
      //     Numero_pedido_Cliente: '45678',
      //     lineapedido_numeropedido: '65432',
      //     Posicion: new Intl.NumberFormat('de-DE').format(4),
      //     Referencia_Cliente: 'Ref456',
      //     Referencia_Technoform: 'TF654',
      //     Confeccion: 'Standard',
      //     Material: 'Wood',
      //     Metros: new Intl.NumberFormat('de-DE').format(10.5),
      //     Longitud: new Intl.NumberFormat('de-DE').format(280),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(55),
      //     Fecha_Pedido: '2025-03-08',
      //     Fecha_Entrega: '2025-04-08',
      //     Cantidad_Estimada: 120,
      //     Produccion_Extranjera: 'No'
      //   },
      //   {
      //     Numero_pedido_Cliente: '56789',
      //     lineapedido_numeropedido: '54321',
      //     Posicion: new Intl.NumberFormat('de-DE').format(5),
      //     Referencia_Cliente: 'Ref567',
      //     Referencia_Technoform: 'TF543',
      //     Confeccion: 'Custom',
      //     Material: 'Copper',
      //     Metros: new Intl.NumberFormat('de-DE').format(22.4),
      //     Longitud: new Intl.NumberFormat('de-DE').format(310),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(65),
      //     Fecha_Pedido: '2025-03-09',
      //     Fecha_Entrega: '2025-04-09',
      //     Cantidad_Estimada: 130,
      //     Produccion_Extranjera: 'Yes'
      //   },
      //   {
      //     Numero_pedido_Cliente: '67890',
      //     lineapedido_numeropedido: '43210',
      //     Posicion: new Intl.NumberFormat('de-DE').format(6),
      //     Referencia_Cliente: 'Ref678',
      //     Referencia_Technoform: 'TF432',
      //     Confeccion: 'Standard',
      //     Material: 'Glass',
      //     Metros: new Intl.NumberFormat('de-DE').format(11.8),
      //     Longitud: new Intl.NumberFormat('de-DE').format(290),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(75),
      //     Fecha_Pedido: '2025-03-10',
      //     Fecha_Entrega: '2025-04-10',
      //     Cantidad_Estimada: 110,
      //     Produccion_Extranjera: 'No'
      //   },
      //   {
      //     Numero_pedido_Cliente: '78901',
      //     lineapedido_numeropedido: '32109',
      //     Posicion: new Intl.NumberFormat('de-DE').format(7),
      //     Referencia_Cliente: 'Ref789',
      //     Referencia_Technoform: 'TF321',
      //     Confeccion: 'Custom',
      //     Material: 'Iron',
      //     Metros: new Intl.NumberFormat('de-DE').format(16.2),
      //     Longitud: new Intl.NumberFormat('de-DE').format(330),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(80),
      //     Fecha_Pedido: '2025-03-11',
      //     Fecha_Entrega: '2025-04-11',
      //     Cantidad_Estimada: 140,
      //     Produccion_Extranjera: 'Yes'
      //   },
      //   {
      //     Numero_pedido_Cliente: '89012',
      //     lineapedido_numeropedido: '21098',
      //     Posicion: new Intl.NumberFormat('de-DE').format(8),
      //     Referencia_Cliente: 'Ref890',
      //     Referencia_Technoform: 'TF210',
      //     Confeccion: 'Standard',
      //     Material: 'Concrete',
      //     Metros: new Intl.NumberFormat('de-DE').format(19.9),
      //     Longitud: new Intl.NumberFormat('de-DE').format(340),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(85),
      //     Fecha_Pedido: '2025-03-12',
      //     Fecha_Entrega: '2025-04-12',
      //     Cantidad_Estimada: 160,
      //     Produccion_Extranjera: 'No'
      //   },
      //   {
      //     Numero_pedido_Cliente: '90123',
      //     lineapedido_numeropedido: '10987',
      //     Posicion: new Intl.NumberFormat('de-DE').format(9),
      //     Referencia_Cliente: 'Ref901',
      //     Referencia_Technoform: 'TF109',
      //     Confeccion: 'Custom',
      //     Material: 'Bronze',
      //     Metros: new Intl.NumberFormat('de-DE').format(20.7),
      //     Longitud: new Intl.NumberFormat('de-DE').format(350),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(90),
      //     Fecha_Pedido: '2025-03-13',
      //     Fecha_Entrega: '2025-04-13',
      //     Cantidad_Estimada: 170,
      //     Produccion_Extranjera: 'Yes'
      //   },
      //   {
      //     Numero_pedido_Cliente: '12345',
      //     lineapedido_numeropedido: '98765',
      //     Posicion: new Intl.NumberFormat('de-DE').format(1),
      //     Referencia_Cliente: 'Ref123',
      //     Referencia_Technoform: 'TF987',
      //     Confeccion: 'Custom',
      //     Material: 'Aluminum',
      //     Metros: new Intl.NumberFormat('de-DE').format(12.5),
      //     Longitud: new Intl.NumberFormat('de-DE').format(300),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(50),
      //     Fecha_Pedido: '2025-03-05',
      //     Fecha_Entrega: '2025-04-05',
      //     Cantidad_Estimada: 100,
      //     Produccion_Extranjera: 'Yes'
      //   },
      //   {
      //     Numero_pedido_Cliente: '23456',
      //     lineapedido_numeropedido: '87654',
      //     Posicion: new Intl.NumberFormat('de-DE').format(2),
      //     Referencia_Cliente: 'Ref234',
      //     Referencia_Technoform: 'TF876',
      //     Confeccion: 'Standard',
      //     Material: 'Steel',
      //     Metros: new Intl.NumberFormat('de-DE').format(15.3),
      //     Longitud: new Intl.NumberFormat('de-DE').format(250),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(60),
      //     Fecha_Pedido: '2025-03-06',
      //     Fecha_Entrega: '2025-04-06',
      //     Cantidad_Estimada: 200,
      //     Produccion_Extranjera: 'No'
      //   },
      //   {
      //     Numero_pedido_Cliente: '34567',
      //     lineapedido_numeropedido: '76543',
      //     Posicion: new Intl.NumberFormat('de-DE').format(3),
      //     Referencia_Cliente: 'Ref345',
      //     Referencia_Technoform: 'TF765',
      //     Confeccion: 'Custom',
      //     Material: 'Plastic',
      //     Metros: new Intl.NumberFormat('de-DE').format(18.7),
      //     Longitud: new Intl.NumberFormat('de-DE').format(320),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(70),
      //     Fecha_Pedido: '2025-03-07',
      //     Fecha_Entrega: '2025-04-07',
      //     Cantidad_Estimada: 150,
      //     Produccion_Extranjera: 'Yes'
      //   },
      //   {
      //     Numero_pedido_Cliente: '45678',
      //     lineapedido_numeropedido: '65432',
      //     Posicion: new Intl.NumberFormat('de-DE').format(4),
      //     Referencia_Cliente: 'Ref456',
      //     Referencia_Technoform: 'TF654',
      //     Confeccion: 'Standard',
      //     Material: 'Wood',
      //     Metros: new Intl.NumberFormat('de-DE').format(10.5),
      //     Longitud: new Intl.NumberFormat('de-DE').format(280),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(55),
      //     Fecha_Pedido: '2025-03-08',
      //     Fecha_Entrega: '2025-04-08',
      //     Cantidad_Estimada: 120,
      //     Produccion_Extranjera: 'No'
      //   },
      //   {
      //     Numero_pedido_Cliente: '56789',
      //     lineapedido_numeropedido: '54321',
      //     Posicion: new Intl.NumberFormat('de-DE').format(5),
      //     Referencia_Cliente: 'Ref567',
      //     Referencia_Technoform: 'TF543',
      //     Confeccion: 'Custom',
      //     Material: 'Copper',
      //     Metros: new Intl.NumberFormat('de-DE').format(22.4),
      //     Longitud: new Intl.NumberFormat('de-DE').format(310),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(65),
      //     Fecha_Pedido: '2025-03-09',
      //     Fecha_Entrega: '2025-04-09',
      //     Cantidad_Estimada: 130,
      //     Produccion_Extranjera: 'Yes'
      //   },
      //   {
      //     Numero_pedido_Cliente: '67890',
      //     lineapedido_numeropedido: '43210',
      //     Posicion: new Intl.NumberFormat('de-DE').format(6),
      //     Referencia_Cliente: 'Ref678',
      //     Referencia_Technoform: 'TF432',
      //     Confeccion: 'Standard',
      //     Material: 'Glass',
      //     Metros: new Intl.NumberFormat('de-DE').format(11.8),
      //     Longitud: new Intl.NumberFormat('de-DE').format(290),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(75),
      //     Fecha_Pedido: '2025-03-10',
      //     Fecha_Entrega: '2025-04-10',
      //     Cantidad_Estimada: 110,
      //     Produccion_Extranjera: 'No'
      //   },
      //   {
      //     Numero_pedido_Cliente: '78901',
      //     lineapedido_numeropedido: '32109',
      //     Posicion: new Intl.NumberFormat('de-DE').format(7),
      //     Referencia_Cliente: 'Ref789',
      //     Referencia_Technoform: 'TF321',
      //     Confeccion: 'Custom',
      //     Material: 'Iron',
      //     Metros: new Intl.NumberFormat('de-DE').format(16.2),
      //     Longitud: new Intl.NumberFormat('de-DE').format(330),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(80),
      //     Fecha_Pedido: '2025-03-11',
      //     Fecha_Entrega: '2025-04-11',
      //     Cantidad_Estimada: 140,
      //     Produccion_Extranjera: 'Yes'
      //   },
      //   {
      //     Numero_pedido_Cliente: '89012',
      //     lineapedido_numeropedido: '21098',
      //     Posicion: new Intl.NumberFormat('de-DE').format(8),
      //     Referencia_Cliente: 'Ref890',
      //     Referencia_Technoform: 'TF210',
      //     Confeccion: 'Standard',
      //     Material: 'Concrete',
      //     Metros: new Intl.NumberFormat('de-DE').format(19.9),
      //     Longitud: new Intl.NumberFormat('de-DE').format(340),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(85),
      //     Fecha_Pedido: '2025-03-12',
      //     Fecha_Entrega: '2025-04-12',
      //     Cantidad_Estimada: 160,
      //     Produccion_Extranjera: 'No'
      //   },
      //   {
      //     Numero_pedido_Cliente: '90123',
      //     lineapedido_numeropedido: '10987',
      //     Posicion: new Intl.NumberFormat('de-DE').format(9),
      //     Referencia_Cliente: 'Ref901',
      //     Referencia_Technoform: 'TF109',
      //     Confeccion: 'Custom',
      //     Material: 'Bronze',
      //     Metros: new Intl.NumberFormat('de-DE').format(20.7),
      //     Longitud: new Intl.NumberFormat('de-DE').format(350),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(90),
      //     Fecha_Pedido: '2025-03-13',
      //     Fecha_Entrega: '2025-04-13',
      //     Cantidad_Estimada: 170,
      //     Produccion_Extranjera: 'Yes'
      //   },
      //   {
      //     Numero_pedido_Cliente: '12345',
      //     lineapedido_numeropedido: '98765',
      //     Posicion: new Intl.NumberFormat('de-DE').format(1),
      //     Referencia_Cliente: 'Ref123',
      //     Referencia_Technoform: 'TF987',
      //     Confeccion: 'Custom',
      //     Material: 'Aluminum',
      //     Metros: new Intl.NumberFormat('de-DE').format(12.5),
      //     Longitud: new Intl.NumberFormat('de-DE').format(300),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(50),
      //     Fecha_Pedido: '2025-03-05',
      //     Fecha_Entrega: '2025-04-05',
      //     Cantidad_Estimada: 100,
      //     Produccion_Extranjera: 'Yes'
      //   },
      //   {
      //     Numero_pedido_Cliente: '23456',
      //     lineapedido_numeropedido: '87654',
      //     Posicion: new Intl.NumberFormat('de-DE').format(2),
      //     Referencia_Cliente: 'Ref234',
      //     Referencia_Technoform: 'TF876',
      //     Confeccion: 'Standard',
      //     Material: 'Steel',
      //     Metros: new Intl.NumberFormat('de-DE').format(15.3),
      //     Longitud: new Intl.NumberFormat('de-DE').format(250),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(60),
      //     Fecha_Pedido: '2025-03-06',
      //     Fecha_Entrega: '2025-04-06',
      //     Cantidad_Estimada: 200,
      //     Produccion_Extranjera: 'No'
      //   },
      //   {
      //     Numero_pedido_Cliente: '34567',
      //     lineapedido_numeropedido: '76543',
      //     Posicion: new Intl.NumberFormat('de-DE').format(3),
      //     Referencia_Cliente: 'Ref345',
      //     Referencia_Technoform: 'TF765',
      //     Confeccion: 'Custom',
      //     Material: 'Plastic',
      //     Metros: new Intl.NumberFormat('de-DE').format(18.7),
      //     Longitud: new Intl.NumberFormat('de-DE').format(320),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(70),
      //     Fecha_Pedido: '2025-03-07',
      //     Fecha_Entrega: '2025-04-07',
      //     Cantidad_Estimada: 150,
      //     Produccion_Extranjera: 'Yes'
      //   },
      //   {
      //     Numero_pedido_Cliente: '45678',
      //     lineapedido_numeropedido: '65432',
      //     Posicion: new Intl.NumberFormat('de-DE').format(4),
      //     Referencia_Cliente: 'Ref456',
      //     Referencia_Technoform: 'TF654',
      //     Confeccion: 'Standard',
      //     Material: 'Wood',
      //     Metros: new Intl.NumberFormat('de-DE').format(10.5),
      //     Longitud: new Intl.NumberFormat('de-DE').format(280),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(55),
      //     Fecha_Pedido: '2025-03-08',
      //     Fecha_Entrega: '2025-04-08',
      //     Cantidad_Estimada: 120,
      //     Produccion_Extranjera: 'No'
      //   },
      //   {
      //     Numero_pedido_Cliente: '56789',
      //     lineapedido_numeropedido: '54321',
      //     Posicion: new Intl.NumberFormat('de-DE').format(5),
      //     Referencia_Cliente: 'Ref567',
      //     Referencia_Technoform: 'TF543',
      //     Confeccion: 'Custom',
      //     Material: 'Copper',
      //     Metros: new Intl.NumberFormat('de-DE').format(22.4),
      //     Longitud: new Intl.NumberFormat('de-DE').format(310),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(65),
      //     Fecha_Pedido: '2025-03-09',
      //     Fecha_Entrega: '2025-04-09',
      //     Cantidad_Estimada: 130,
      //     Produccion_Extranjera: 'Yes'
      //   },
      //   {
      //     Numero_pedido_Cliente: '67890',
      //     lineapedido_numeropedido: '43210',
      //     Posicion: new Intl.NumberFormat('de-DE').format(6),
      //     Referencia_Cliente: 'Ref678',
      //     Referencia_Technoform: 'TF432',
      //     Confeccion: 'Standard',
      //     Material: 'Glass',
      //     Metros: new Intl.NumberFormat('de-DE').format(11.8),
      //     Longitud: new Intl.NumberFormat('de-DE').format(290),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(75),
      //     Fecha_Pedido: '2025-03-10',
      //     Fecha_Entrega: '2025-04-10',
      //     Cantidad_Estimada: 110,
      //     Produccion_Extranjera: 'No'
      //   },
      //   {
      //     Numero_pedido_Cliente: '78901',
      //     lineapedido_numeropedido: '32109',
      //     Posicion: new Intl.NumberFormat('de-DE').format(7),
      //     Referencia_Cliente: 'Ref789',
      //     Referencia_Technoform: 'TF321',
      //     Confeccion: 'Custom',
      //     Material: 'Iron',
      //     Metros: new Intl.NumberFormat('de-DE').format(16.2),
      //     Longitud: new Intl.NumberFormat('de-DE').format(330),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(80),
      //     Fecha_Pedido: '2025-03-11',
      //     Fecha_Entrega: '2025-04-11',
      //     Cantidad_Estimada: 140,
      //     Produccion_Extranjera: 'Yes'
      //   },
      //   {
      //     Numero_pedido_Cliente: '89012',
      //     lineapedido_numeropedido: '21098',
      //     Posicion: new Intl.NumberFormat('de-DE').format(8),
      //     Referencia_Cliente: 'Ref890',
      //     Referencia_Technoform: 'TF210',
      //     Confeccion: 'Standard',
      //     Material: 'Concrete',
      //     Metros: new Intl.NumberFormat('de-DE').format(19.9),
      //     Longitud: new Intl.NumberFormat('de-DE').format(340),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(85),
      //     Fecha_Pedido: '2025-03-12',
      //     Fecha_Entrega: '2025-04-12',
      //     Cantidad_Estimada: 160,
      //     Produccion_Extranjera: 'No'
      //   },
      //   {
      //     Numero_pedido_Cliente: '90123',
      //     lineapedido_numeropedido: '10987',
      //     Posicion: new Intl.NumberFormat('de-DE').format(9),
      //     Referencia_Cliente: 'Ref901',
      //     Referencia_Technoform: 'TF109',
      //     Confeccion: 'Custom',
      //     Material: 'Bronze',
      //     Metros: new Intl.NumberFormat('de-DE').format(20.7),
      //     Longitud: new Intl.NumberFormat('de-DE').format(350),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(90),
      //     Fecha_Pedido: '2025-03-13',
      //     Fecha_Entrega: '2025-04-13',
      //     Cantidad_Estimada: 170,
      //     Produccion_Extranjera: 'Yes'
      //   },
      //   {
      //     Numero_pedido_Cliente: '12345',
      //     lineapedido_numeropedido: '98765',
      //     Posicion: new Intl.NumberFormat('de-DE').format(1),
      //     Referencia_Cliente: 'Ref123',
      //     Referencia_Technoform: 'TF987',
      //     Confeccion: 'Custom',
      //     Material: 'Aluminum',
      //     Metros: new Intl.NumberFormat('de-DE').format(12.5),
      //     Longitud: new Intl.NumberFormat('de-DE').format(300),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(50),
      //     Fecha_Pedido: '2025-03-05',
      //     Fecha_Entrega: '2025-04-05',
      //     Cantidad_Estimada: 100,
      //     Produccion_Extranjera: 'Yes'
      //   },
      //   {
      //     Numero_pedido_Cliente: '23456',
      //     lineapedido_numeropedido: '87654',
      //     Posicion: new Intl.NumberFormat('de-DE').format(2),
      //     Referencia_Cliente: 'Ref234',
      //     Referencia_Technoform: 'TF876',
      //     Confeccion: 'Standard',
      //     Material: 'Steel',
      //     Metros: new Intl.NumberFormat('de-DE').format(15.3),
      //     Longitud: new Intl.NumberFormat('de-DE').format(250),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(60),
      //     Fecha_Pedido: '2025-03-06',
      //     Fecha_Entrega: '2025-04-06',
      //     Cantidad_Estimada: 200,
      //     Produccion_Extranjera: 'No'
      //   },
      //   {
      //     Numero_pedido_Cliente: '34567',
      //     lineapedido_numeropedido: '76543',
      //     Posicion: new Intl.NumberFormat('de-DE').format(3),
      //     Referencia_Cliente: 'Ref345',
      //     Referencia_Technoform: 'TF765',
      //     Confeccion: 'Custom',
      //     Material: 'Plastic',
      //     Metros: new Intl.NumberFormat('de-DE').format(18.7),
      //     Longitud: new Intl.NumberFormat('de-DE').format(320),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(70),
      //     Fecha_Pedido: '2025-03-07',
      //     Fecha_Entrega: '2025-04-07',
      //     Cantidad_Estimada: 150,
      //     Produccion_Extranjera: 'Yes'
      //   },
      //   {
      //     Numero_pedido_Cliente: '45678',
      //     lineapedido_numeropedido: '65432',
      //     Posicion: new Intl.NumberFormat('de-DE').format(4),
      //     Referencia_Cliente: 'Ref456',
      //     Referencia_Technoform: 'TF654',
      //     Confeccion: 'Standard',
      //     Material: 'Wood',
      //     Metros: new Intl.NumberFormat('de-DE').format(10.5),
      //     Longitud: new Intl.NumberFormat('de-DE').format(280),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(55),
      //     Fecha_Pedido: '2025-03-08',
      //     Fecha_Entrega: '2025-04-08',
      //     Cantidad_Estimada: 120,
      //     Produccion_Extranjera: 'No'
      //   },
      //   {
      //     Numero_pedido_Cliente: '56789',
      //     lineapedido_numeropedido: '54321',
      //     Posicion: new Intl.NumberFormat('de-DE').format(5),
      //     Referencia_Cliente: 'Ref567',
      //     Referencia_Technoform: 'TF543',
      //     Confeccion: 'Custom',
      //     Material: 'Copper',
      //     Metros: new Intl.NumberFormat('de-DE').format(22.4),
      //     Longitud: new Intl.NumberFormat('de-DE').format(310),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(65),
      //     Fecha_Pedido: '2025-03-09',
      //     Fecha_Entrega: '2025-04-09',
      //     Cantidad_Estimada: 130,
      //     Produccion_Extranjera: 'Yes'
      //   },
      //   {
      //     Numero_pedido_Cliente: '67890',
      //     lineapedido_numeropedido: '43210',
      //     Posicion: new Intl.NumberFormat('de-DE').format(6),
      //     Referencia_Cliente: 'Ref678',
      //     Referencia_Technoform: 'TF432',
      //     Confeccion: 'Standard',
      //     Material: 'Glass',
      //     Metros: new Intl.NumberFormat('de-DE').format(11.8),
      //     Longitud: new Intl.NumberFormat('de-DE').format(290),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(75),
      //     Fecha_Pedido: '2025-03-10',
      //     Fecha_Entrega: '2025-04-10',
      //     Cantidad_Estimada: 110,
      //     Produccion_Extranjera: 'No'
      //   },
      //   {
      //     Numero_pedido_Cliente: '78901',
      //     lineapedido_numeropedido: '32109',
      //     Posicion: new Intl.NumberFormat('de-DE').format(7),
      //     Referencia_Cliente: 'Ref789',
      //     Referencia_Technoform: 'TF321',
      //     Confeccion: 'Custom',
      //     Material: 'Iron',
      //     Metros: new Intl.NumberFormat('de-DE').format(16.2),
      //     Longitud: new Intl.NumberFormat('de-DE').format(330),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(80),
      //     Fecha_Pedido: '2025-03-11',
      //     Fecha_Entrega: '2025-04-11',
      //     Cantidad_Estimada: 140,
      //     Produccion_Extranjera: 'Yes'
      //   },
      //   {
      //     Numero_pedido_Cliente: '89012',
      //     lineapedido_numeropedido: '21098',
      //     Posicion: new Intl.NumberFormat('de-DE').format(8),
      //     Referencia_Cliente: 'Ref890',
      //     Referencia_Technoform: 'TF210',
      //     Confeccion: 'Standard',
      //     Material: 'Concrete',
      //     Metros: new Intl.NumberFormat('de-DE').format(19.9),
      //     Longitud: new Intl.NumberFormat('de-DE').format(340),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(85),
      //     Fecha_Pedido: '2025-03-12',
      //     Fecha_Entrega: '2025-04-12',
      //     Cantidad_Estimada: 160,
      //     Produccion_Extranjera: 'No'
      //   },
      //   {
      //     Numero_pedido_Cliente: '90123',
      //     lineapedido_numeropedido: '10987',
      //     Posicion: new Intl.NumberFormat('de-DE').format(9),
      //     Referencia_Cliente: 'Ref901',
      //     Referencia_Technoform: 'TF109',
      //     Confeccion: 'Custom',
      //     Material: 'Bronze',
      //     Metros: new Intl.NumberFormat('de-DE').format(20.7),
      //     Longitud: new Intl.NumberFormat('de-DE').format(350),
      //     Varillas_Paquete: new Intl.NumberFormat('de-DE').format(90),
      //     Fecha_Pedido: '2025-03-13',
      //     Fecha_Entrega: '2025-04-13',
      //     Cantidad_Estimada: 170,
      //     Produccion_Extranjera: 'Yes'
      //   }
      // ]}
      data={fetchedOrders?.data}
      loading={fetchedOrders?.loading}
      error={fetchedOrders?.error}
      // handleClick={handleClick}
    />
  )
}

import { useUser } from 'contexts/UserContext'
import { menuItems } from 'data/menuItems'
import { useLocation } from 'wouter'
import MenuItems from './MenuItems'
import PropTypes from 'prop-types'

export default function Menu({ setIsSidebarOpen }) {
  const { user } = useUser()
  const [location] = useLocation()

  return (
    <ul className="space-y-2 font-medium list-none">
      {menuItems &&
        menuItems.map((item, index) => {
          return (
            <MenuItems
              key={index}
              item={item}
              user={user}
              location={location}
              setIsSidebarOpen={setIsSidebarOpen}
            />
          )
        })}
    </ul>
  )
}

Menu.propTypes = {
  setIsSidebarOpen: PropTypes.func
}

import { useUser } from 'contexts/UserContext'
import { Field, Form, Formik } from 'formik'
import LoginForm from 'forms/Login/LoginForm'
import useFetch from 'hooks/useFetch'
import { useEffect, useState } from 'react'
import { CgSpinner } from 'react-icons/cg'
import Modal from 'react-modal'
import { useLocation } from 'wouter'
import * as Yup from 'yup'

Modal.setAppElement('#root') // Para accesibilidad, establece el elemento principal de la aplicación

export default function Login() {
  const { setUser } = useUser()
  const [, setLocation] = useLocation()

  // useFetch(): hook personalizado para realizar solicitudes GET, POST, PUT, DELETE
  const [verifiedEmail, verifyEmail] = useFetch()
  const [requestedToken, requestToken] = useFetch()
  const [requestedReset, requestReset] = useFetch()
  const [changedPassword, changePassword] = useFetch()

  // useState()
  const [isVerificationModalOpen, setVerificationModalOpen] = useState(false)
  const [isPwdRecoveryModalOpen, setPwdRecoveryModalOpen] = useState(false)
  const [pwdRecoveryStep, setPwdRecoveryStep] = useState(1)
  const [verificationCode, setVerificationCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [username, setUsername] = useState(null)
  const [step1Error, setStep1Error] = useState(null)
  const [step2Error, setStep2Error] = useState(null)

  // CRUD: POST
  const handleEmailVerification = async (values) => {
    const apiCallParams = {
      url: 'auth/2fa/verify',
      method: 'POST',
      body: values,
      messageKo: 'Usuario y/o contraseña incorrectos'
    }
    await verifyEmail(apiCallParams)
  }

  // CRUD: POST
  const handleToken = async (values) => {
    const apiCallParams = {
      url: 'auth/token',
      method: 'POST',
      body: {
        code: verificationCode,
        email: verifiedEmail.data.email,
        password: verifiedEmail.data.password
      },
      messageKo: 'PIN incorrecte'
    }
    await requestToken(apiCallParams)
  }

  // CRUD: POST
  const handleRequestReset = async (values) => {
    setUsername(values.email)

    const apiCallParams = {
      url: 'auth/request-reset',
      method: 'POST',
      body: {
        email: values.email
      },
      messageKo: 'PIN incorrecte'
    }
    await requestReset(apiCallParams)
  }

  // CRUD: POST
  const handleResetPassword = async (values) => {
    const apiCallParams = {
      url: 'auth/reset-password',
      method: 'POST',
      body: {
        codeEmail: values.codeEmail,
        password: values.password,
        username
      },
      messageKo: 'PIN incorrecte'
    }
    await changePassword(apiCallParams)
  }

  // Escuchar POST
  useEffect(() => {
    if (verifiedEmail.data) {
      setVerificationModalOpen(true)
    }

    if (requestedToken.data) {
      setUser(requestedToken.data)
      setLocation('/orders/grid')
    }
  }, [verifiedEmail.data, requestedToken.data])

  // Escuchar POST
  useEffect(() => {
    if (requestedReset.data) {
      setPwdRecoveryStep(2)
      setLoading(false)
      setStep1Error(false)
    }

    if (requestedReset.error) {
      setPwdRecoveryStep(1)
      setLoading(false)
      setStep1Error(true)
    }
  }, [requestedReset.data, requestedReset.error])

  // Escuchar POST
  useEffect(() => {
    if (changedPassword.data) {
      setPwdRecoveryModalOpen(false)
      setPwdRecoveryStep(1)
      setLoading(false)
      setStep2Error(false)
    }

    if (changedPassword.error) {
      setPwdRecoveryStep(2)
      setLoading(false)
      setStep2Error(true)
    }
  }, [changedPassword.data, changedPassword.error])

  // Esquema validación formulario:
  const validationSchema1 = Yup.object().shape({
    email: Yup.string().email('Email inválido').required('Introduzca un email')
  })

  // Esquema validación formulario:
  const validationSchema2 = Yup.object().shape({
    codeEmail: Yup.string().required('Introduzca un código'),
    password: Yup.string()
      .required('Introduzca una contraseña')
      .oneOf([Yup.ref('repeatPassword')], 'Las contraseñas deben coincidir')
      .min(6, 'La contraseña es demasiado corta. Mínimo 6 caracteres'),
    repeatPassword: Yup.string()
      .required('Introduzca una contraseña')
      .oneOf([Yup.ref('password')], 'Las contraseñas deben coincidir')
      .min(6, 'La contraseña es demasiado corta. Mínimo 6 caracteres')
  })

  return (
    <div className="fixed top-0 flex flex-col items-center justify-center w-full h-screen px-2 pt-4 pb-2 md:p-10">
      <div className="relative grid w-full h-full p-4 md:p-12 grid-rows-8 md:grid-rows-11 auto-rows-min border-container border-[10px] md:border-[20px] border-secondary">
        <div className="absolute px-4 pb-0 pt-0 md:pt-0.5 text-4xl bg-white font-notoBold text-secondary -top-6 md:-top-8 right-0 md:right-[90px]">
          TECHNOFORM
        </div>

        <div className="flex justify-start w-full row-span-2 pb-4 md:row-span-5 place-items-end">
          <div className="space-y-2 md:space-y-0">
            <h1 className="m-0 space-x-2 text-4xl leading-none md:text-9xl">
              <span className="font-notoBold text-primary">Mi</span>

              <span className="uppercase font-notoBold text-secondary">
                Technoform
              </span>
            </h1>

            <h2 className="m-0 text-2xl leading-none font-notoExtraBold md:text-6xl ps-0 md:ps-1.5 text-primary">
              Extranet service
            </h2>
          </div>
        </div>

        <div className="w-full row-span-5 md:pl-2 place-content-end">
          <div className="w-full max-w-lg">
            <LoginForm
              loading={verifiedEmail.loading}
              handleSubmit={handleEmailVerification}
              setPwdRecoveryModalOpen={setPwdRecoveryModalOpen}
            />

            <div
              className={`relative px-2 py-1 mt-2 text-red-700 bg-red-100 border border-red-400 rounded-sm ${verifiedEmail.error ? 'visible' : 'invisible'}`}
              role="alert"
            >
              <strong className="font-notoBlack">Error: </strong>
              <span className="block sm:inline font-noto">
                ¡Usuario o contraseña incorrectos!
              </span>
            </div>
          </div>
        </div>

        <div className="w-full row-span-1 md:pl-2 place-content-end">
          <p className="text-xl font-notoBold md:text-3xl text-secondary">
            Soluciones aislantes para ventanas, puertas y fachadas
          </p>
        </div>
      </div>

      {/* MODAL - ACCEDER */}
      <Modal
        isOpen={isVerificationModalOpen}
        onRequestClose={() => setVerificationModalOpen((prev) => !prev)}
        contentLabel="Código de Verificación"
        style={{
          overlay: { backgroundColor: 'rgba(0, 0, 0, 0.75)' },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            background: 'transparent',
            padding: '0px',
            border: '0px',
            width: '50%',
            height: '50%'
          }
        }}
      >
        <div className="relative w-full h-full">
          <div className="relative flex flex-col h-full bg-white border-4 rounded-sm border-secondary">
            <div className="flex items-center justify-between p-4 border-b border-gray-300 rounded-t md:p-5">
              <h3 className="text-lg text-gray-900 font-notoBold">
                Código de verificación
              </h3>

              <button
                onClick={() => setVerificationModalOpen((prev) => !prev)}
                className="inline-flex items-center justify-center w-8 h-8 text-sm text-gray-400 bg-transparent rounded-lg hover:bg-gray-200 hover:text-gray-900 ms-auto"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>

                <span className="sr-only">Close modal</span>
              </button>
            </div>

            <form className="flex flex-col items-end p-4 grow md:p-5">
              <div className="grid w-full grid-cols-2 gap-4 mb-4">
                <div className="col-span-2">
                  <input
                    type="text"
                    className="border border-gray-300 text-gray-900 text-sm font-noto rounded-sm outline-none block w-full p-2.5"
                    placeholder="Ingrese el código..."
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/\D/g, '') // Removes all non-numeric characters
                    }}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    value={verificationCode}
                  />
                </div>
              </div>

              <div className="flex items-end justify-end grow">
                <button
                  type="button"
                  className="h-10 px-4 text-lg text-white transition-colors duration-150 outline-none font-notoExtraCondensedBlack hover:text-primary bg-primary hover:bg-secondary"
                  onClick={() => handleToken()}
                  style={{
                    border: '3px solid #00c8bb',
                    borderRadius: '15px'
                  }}
                >
                  Verificar
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      {/* MODAL - RECUPERAR CONTRASEÑA */}
      <Modal
        isOpen={isPwdRecoveryModalOpen}
        onRequestClose={() => {
          setPwdRecoveryModalOpen((prev) => !prev)
          setPwdRecoveryStep(1)
          setUsername(null)
          setLoading(false)
          setStep1Error(false)
          setStep2Error(false)
        }}
        contentLabel="Código de Verificación"
        style={{
          overlay: { backgroundColor: 'rgba(0, 0, 0, 0.75)' },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            background: 'transparent',
            padding: '0px',
            border: '0px',
            width: '50%',
            height: '50%'
          }
        }}
      >
        <div className="relative w-full h-full">
          <div className="relative flex flex-col h-full bg-white border-4 rounded-sm border-secondary">
            <div className="flex items-center justify-between p-4 border-b border-gray-300 rounded-t md:p-5">
              <h3 className="text-lg text-gray-900 font-notoBold">
                Recuperar contraseña
              </h3>

              <button
                onClick={() => {
                  setPwdRecoveryModalOpen((prev) => !prev)
                  setPwdRecoveryStep(1)
                  setUsername(null)
                  setLoading(false)
                  setStep1Error(false)
                  setStep2Error(false)
                }}
                className="inline-flex items-center justify-center w-8 h-8 text-sm text-gray-400 bg-transparent rounded-lg hover:bg-gray-200 hover:text-gray-900 ms-auto"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>

                <span className="sr-only">Close modal</span>
              </button>
            </div>

            {loading ? (
              <div className="flex items-center justify-center w-full h-full">
                <CgSpinner size={50} className="animate-spin" />
              </div>
            ) : pwdRecoveryStep === 1 ? (
              <Formik
                initialValues={{
                  email: ''
                }}
                validationSchema={validationSchema1}
                onSubmit={(values) => {
                  setLoading(true)
                  handleRequestReset(values)
                }}
              >
                {({ errors, touched }) => (
                  <Form className="flex flex-col p-4 grow md:p-5">
                    <div className="flex flex-col w-full">
                      <div className="w-full">
                        <label
                          htmlFor="email"
                          className="block mb-1 font-medium text-gray-900"
                        >
                          Usuario
                        </label>

                        <Field
                          type="email"
                          id="email"
                          name="email"
                          placeholder="example@example.com"
                          className="border border-gray-300 text-gray-900 text-sm font-noto rounded-sm outline-none block w-full p-2.5"
                        />
                      </div>

                      <div
                        className={`flex justify-start items-start w-full ${touched.email && errors.email ? 'visible' : 'invisible'}`}
                      >
                        <div className="flex w-full">
                          <p className="mt-0.5 text-sm text-red-600 leading-none font-noto">
                            {errors.email || '\u00A0'}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="flex items-end justify-between grow">
                      <div
                        className={`relative px-2 py-1 mt-2 text-red-700 bg-red-100 border border-red-400 rounded-sm ${step1Error ? 'visible' : 'invisible'}`}
                        role="alert"
                      >
                        <strong className="font-notoBlack">Error: </strong>
                        <span className="block sm:inline font-noto">
                          ¡Correo incorrecto!
                        </span>
                      </div>

                      <button
                        type="submit"
                        className="h-10 px-4 text-lg text-white transition-colors duration-150 outline-none font-notoExtraCondensedBlack hover:text-primary bg-primary hover:bg-secondary"
                        style={{
                          border: '3px solid #00c8bb',
                          borderRadius: '15px'
                        }}
                      >
                        Enviar correo
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : pwdRecoveryStep === 2 ? (
              <Formik
                initialValues={{
                  codeEmail: '',
                  password: '',
                  repeatPassword: ''
                }}
                validationSchema={validationSchema2}
                onSubmit={(values) => {
                  setLoading(true)
                  handleResetPassword(values)
                }}
              >
                {({ errors, touched }) => (
                  <Form className="flex flex-col p-4 grow md:p-5">
                    <div className="w-full space-y-3">
                      <div className="flex flex-col w-full">
                        <div className="w-full">
                          <label
                            htmlFor="codeEmail"
                            className="block mb-1 font-medium text-gray-900"
                          >
                            Código
                          </label>

                          <Field
                            type="text"
                            id="codeEmail"
                            name="codeEmail"
                            className="border border-gray-300 text-gray-900 text-sm font-noto rounded-sm outline-none block w-full p-2.5"
                            placeholder="Ingrese el código..."
                            autocomplete="random-code"
                          />
                        </div>

                        <div
                          className={`flex justify-start items-start w-full ${touched.codeEmail && errors.codeEmail ? 'visible' : 'invisible'}`}
                        >
                          <div className="flex w-full">
                            <p className="mt-0.5 text-sm text-red-600 leading-none font-noto">
                              {errors.codeEmail || '\u00A0'}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col w-full">
                        <div className="w-full">
                          <label
                            htmlFor="password"
                            className="block mb-1 font-medium text-gray-900"
                          >
                            Contraseña
                          </label>

                          <Field
                            type="password"
                            id="password"
                            name="password"
                            className="border border-gray-300 text-gray-900 text-sm font-noto rounded-sm outline-none block w-full p-2.5"
                            placeholder="Ingrese la nueva contraseña..."
                            autocomplete="random-password"
                          />
                        </div>

                        <div
                          className={`flex justify-start items-start w-full ${touched.password && errors.password && touched.repeatPassword ? 'visible' : 'invisible'}`}
                        >
                          <div className="flex w-full">
                            <p className="mt-0.5 text-sm text-red-600 leading-none font-noto">
                              {errors.password || '\u00A0'}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col w-full">
                        <div className="w-full">
                          <label
                            htmlFor="repeatPassword"
                            className="block mb-1 font-medium text-gray-900"
                          >
                            Repetir contraseña
                          </label>

                          <Field
                            type="password"
                            id="repeatPassword"
                            name="repeatPassword"
                            className="border border-gray-300 text-gray-900 text-sm font-noto rounded-sm outline-none block w-full p-2.5"
                            placeholder="Repite la nueva contraseña..."
                            autocomplete="random-password"
                          />
                        </div>

                        <div
                          className={`flex justify-start items-start w-full ${touched.repeatPassword && errors.repeatPassword && touched.password ? 'visible' : 'invisible'}`}
                        >
                          <div className="flex w-full">
                            <p className="mt-0.5 text-sm text-red-600 leading-none font-noto">
                              {errors.repeatPassword || '\u00A0'}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex items-end justify-between grow">
                      <div
                        className={`relative px-2 py-1 mt-2 text-red-700 bg-red-100 border border-red-400 rounded-sm ${step2Error ? 'visible' : 'invisible'}`}
                        role="alert"
                      >
                        <strong className="font-notoBlack">Error: </strong>
                        <span className="block sm:inline font-noto">
                          ¡Código incorrecto!
                        </span>
                      </div>

                      <button
                        type="submit"
                        className="h-10 px-4 text-lg text-white transition-colors duration-150 outline-none font-notoExtraCondensedBlack hover:text-primary bg-primary hover:bg-secondary"
                        style={{
                          border: '3px solid #00c8bb',
                          borderRadius: '15px'
                        }}
                      >
                        Verificar
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : (
              ''
            )}
          </div>
        </div>
      </Modal>
    </div>
  )
}

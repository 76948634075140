import { ModalProvider } from 'contexts/ModalContext'
import ModalManager from 'contexts/ModalManager'
import { TitleProvider } from 'contexts/TitleContext'
import { UserProvider } from 'contexts/UserContext'
import React from 'react'
import ReactDOM from 'react-dom/client'
import './i18n'
import './index.css'
import App from './pages/App'

// Este archivo es el punto de entrada principal de la aplicación.
// Aquí se configura el renderizado inicial y los contextos globales.
ReactDOM.createRoot(document.getElementById('root')).render(
  // Modo estricto de React para detectar problemas y advertencias, se aplica automáticamente durante el desarrollo, en producción no
  <React.StrictMode>
    {/* Proveedor global del estado del usuario */}
    <UserProvider>
      {/* Proveedor global para gestionar el título de la página actual */}
      <TitleProvider>
        {/* Proveedor global para gestionar los modal */}
        <ModalProvider>
          <>
            {/* Componente principal de la aplicación */}
            <App />

            {/* Componente que controla los modales */}
            <ModalManager />
          </>
        </ModalProvider>
      </TitleProvider>
    </UserProvider>
  </React.StrictMode>
)

export const menuItems = [
  // {
  //   title: 'Compras',
  //   slug: '',
  //   active: true,
  //   requiredPermissions: null,
  //   subMenuItems: [
  //     {
  //       title: 'Precios',
  //       slug: '#',
  //       active: true,
  //       subMenu: true
  //     },
  //     {
  //       title: 'Estadísticas',
  //       slug: '#',
  //       active: true,
  //       subMenu: true
  //     },
  //     {
  //       title: 'Ratios',
  //       slug: '#',
  //       active: true,
  //       subMenu: true
  //     },
  //     {
  //       title: 'Visitas',
  //       slug: '#',
  //       active: true,
  //       subMenu: true
  //     }
  //   ]
  // },
  {
    title: 'menu_items.logistics',
    slug: '',
    active: true,
    requiredPermissions: null,
    subMenuItems: [
      {
        title: 'submenu_items.orders',
        slug: '/orders/grid',
        active: true,
        subMenu: true
      },
      // {
      //   title: 'Confirmaciones',
      //   slug: '#',
      //   active: true,
      //   subMenu: true
      // },
      // {
      //   title: 'Albaranes',
      //   slug: '#',
      //   active: true,
      //   subMenu: true
      // },
      // {
      //   title: 'Facturas',
      //   slug: '#',
      //   active: true,
      //   subMenu: true
      // }
    ]
  },
  {
    title: 'menu_items.technical_department',
    slug: '',
    active: true,
    requiredPermissions: null,
    subMenuItems: [
      // {
      //   title: 'Catálogo estándar',
      //   slug: '#',
      //   active: true,
      //   subMenu: true
      // },
      // {
      //   title: 'Selector de planos',
      //   slug: '#',
      //   active: true,
      //   subMenu: true
      // },
      {
        title: 'submenu_items.uw_simulator',
        slug: '/uw-simulator',
        active: true,
        subMenu: true
      },
      // {
      //   title: 'Ficheros BIM',
      //   slug: '#',
      //   active: true,
      //   subMenu: true
      // }
    ]
  },
  // {
  //   title: 'Documentos',
  //   slug: '#',
  //   active: true,
  //   requiredPermissions: null
  // },
  // {
  //   title: 'Ficha cliente',
  //   slug: '#',
  //   active: true,
  //   requiredPermissions: null
  // },
  // {
  //   title: 'Historico descargas',
  //   slug: '#',
  //   active: true,
  //   requiredPermissions: null
  // },
  // {
  //   title: 'Simulaciones guardadas',
  //   slug: '#',
  //   active: true,
  //   requiredPermissions: null
  // },
  // {
  //   title: 'Notificaciones',
  //   slug: '#',
  //   active: true,
  //   requiredPermissions: null
  // },
  {
    title: 'menu_items.contact',
    slug: '#',
    active: true,
    requiredPermissions: null
  }
  // {
  //   title: 'Administrar',
  //   slug: '',
  //   active: false,
  //   subMenu: false
  // },
  // {
  //   title: 'Usuarios',
  //   slug: '/admin/grid',
  //   active: true,
  //   subMenu: true
  // },
  // {
  //   title: 'Cerrar',
  //   slug: '/logout',
  //   active: true,
  //   subMenu: false,
  //   hasSubMenuItems: false
  // }
  // {
  //   title: 'Cerrar',
  //   slug: '/logout',
  //   active: true,
  //   requiredPermissions: null
  // }
]

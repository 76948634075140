import { useTitle } from 'contexts/TitleContext'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'wouter'
import { useEffect } from 'react'

export default function NotFound() {
  const [, setLocation] = useLocation()
  const { setTitle } = useTitle()
  const { t } = useTranslation()

  useEffect(() => {
    setTitle({ name: t('not_found.error') })
  }, [])

  return (
    <section className="h-full">
      <div className="container flex items-center h-full px-6 py-12 mx-auto">
        <div>
          <p className="text-sm font-medium text-accent dark:text-accent">
            {t('not_found.error')}
          </p>
          <h1 className="mt-3 text-2xl font-semibold text-gray-800 dark:text-white md:text-3xl">
            {t('not_found.title')}
          </h1>
          <p className="mt-4 text-gray-500 dark:text-gray-400">
            {t('not_found.subtitle')}
          </p>

          <div className="flex items-center mt-6 gap-x-3">
            <button
              type="button"
              onClick={() => setLocation('/')}
              className="h-10 px-4 border-[3px] border-secondary rounded-[15px] font-notoExtraCondensedBlack text-primary transition-colors duration-150 outline-none bg-white hover:bg-secondary"
            >
              {t('not_found.button')}
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

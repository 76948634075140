import Layout from 'components/layout/Layout'
import { useUser } from 'contexts/UserContext'
import Login from 'pages/Login/Login'
import Logout from 'pages/Login/Logout'
import OrderViewGrid from 'pages/Logistics/OrderViewGrid'
import UserViewDetail from 'pages/User/UserViewDetail'
import UserViewGrid from 'pages/User/UserViewGrid'
import PropTypes from 'prop-types'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Redirect, Route, Switch } from 'wouter'
import UwSimulatorViewDetail from './TechnicalDepartment/UwSimulatorViewDetail'
import NotFound from './NotFound/NotFound'

// Rutas privadas que se pueden usar si se ha iniciado sesión:
const PrivateRoute = ({ isLoggedIn, ...props }) => {
  if (!isLoggedIn) {
    return <Redirect to="/login" />
  }

  return (
    <Layout>
      <Route {...props} />
    </Layout>
  )
}

// Ruta 'LOG IN' que se usa si no se ha iniciado sesión:
const LoginRoute = ({ isLoggedIn, ...props }) =>
  isLoggedIn ? <Redirect to="/orders/grid" /> : <Route {...props} />

export default function App() {
  const { user } = useUser()
  const isLoggedIn =
    user &&
    user !== undefined &&
    user !== null &&
    user.token &&
    user.token !== undefined &&
    user.token !== null &&
    user.AccessToken &&
    user.AccessToken !== undefined &&
    user.AccessToken !== null

  return (
    <>
      <Switch>
        {/* INICIO SESIÓN / CIERRE SESIÓN */}
        <LoginRoute isLoggedIn={isLoggedIn} path="/login" component={Login} />

        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/logout"
          component={Logout}
        />
        {/* FIN INICIO SESIÓN / CIERRE SESIÓN */}

        {/* PANEL PRINCIPAL */}
        <PrivateRoute
          path="/"
          render={() =>
            isLoggedIn ? (
              <Redirect to="/orders/grid" />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        {/* FIN PANEL PRINCIPAL */}

        {/* PEDIDOS */}
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/orders/grid"
          component={OrderViewGrid}
        />
        {/* FIN PEDIDOS */}

        {/* SIMULADOR UW */}
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/uw-simulator"
          component={UwSimulatorViewDetail}
        />
        {/* FIN SIMULADOR UW */}

        {/* USUARIOS */}
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/users/grid"
          component={UserViewGrid}
        />

        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/users/:id"
          component={UserViewDetail}
        />
        {/* FIN USUARIOS -> */}

        {/* 404 - NOT FOUND */}
        <PrivateRoute isLoggedIn={isLoggedIn} path="*" component={NotFound} />
        {/* FIN 404 - NOT FOUND */}
      </Switch>

      {/* 
        'ToastContainer' es un contenedor para mostrar mensajes flotantes, generalmente se usa para mensajes de éxito o error tras llamadas a la API. 
        No mover de aquí, ya que aquí funciona correctamente. Si lo pones en otro lugar de la app el botón de cerrar o las animaciones no funcionan. 
        Los toasts se crean y se referencian automáticamente a este componente para mostrarse. 
        Para más info: https://fkhadra.github.io/react-toastify/introduction/
      */}
      <ToastContainer />
    </>
  )
}

PrivateRoute.propTypes = {
  isLoggedIn: PropTypes.bool
}

LoginRoute.propTypes = {
  isLoggedIn: PropTypes.bool
}

import Menu from 'components/layout/Menu/Menu'
import LogoGestinet from 'components/ui/Logo/LogoGestinet'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link } from 'wouter'

export default function Sidebar({ isSidebarOpen, setIsSidebarOpen }) {
  const { t } = useTranslation()

  return (
    <>
      {/* Barra lateral para dispositivos con pantallas mayores a 768px (tablets grandes, portátiles, pantallas grandes) */}
      <aside
        id="logo-sidebar"
        className="fixed top-0 left-0 z-40 h-screen font-normal bg-primary transition-width duration-100 pt-[104px] hidden md:flex md:flex-col md:flex-shrink-0 w-0 md:w-60"
        // className="fixed top-0 left-0 z-40 h-screen transition-transform -translate-x-full print:hidden bg-primary w-60 pt-[104px] pb-24 sm:translate-x-0 pr-1"
        // className="fixed top-0 left-0 z-40 h-screen mt-2 transition-transform -translate-x-full pt-[134px] print:hidden bg-primary w-60 sm:translate-x-0"
        // className="fixed top-0 left-0 z-40 h-screen transition-transform -translate-x-full pt-28 print:hidden bg-primary w-60 sm:translate-x-0"
        aria-label="Sidebar"
      >
        <div
          id="sidebar-menu"
          className="h-full px-3 pb-4 overflow-y-auto bg-primary"
        >
          <Menu />
        </div>

        <div className="fixed w-60 bottom-12 flex items-center justify-center m-auto px-3 h-12 border-t-2 border-secondary font-medium list-none">
          <Link className="w-full" href="/logout">
            <div className="flex items-center justify-between px-2 py-1 group border-s-2 text-white border-white hover:border-secondary hover:text-secondary focus:outline-none focus:border-secondary focus:text-secondary">
              <span className="text-lg uppercase font-notoExtraCondensedBlack ms-1">
                {t('sidebar.logout')}
              </span>
            </div>
          </Link>
        </div>

        <LogoGestinet
          parentClasses={
            'fixed w-60 bottom-0 flex items-center justify-center m-auto h-12 border-t-2 border-secondary'
          }
          imgClasses={'w-20'}
          link={'https://www.gestinet.com'}
        />
      </aside>

      {/* Barra lateral para dispositivos con pantallas menores a 768px (móviles, tablets pequeñas) */}
      <aside
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-20 h-screen flex flex-col flex-shrink-0 font-normal bg-primary dark:bg-[#38393D] transition-width duration-100 pt-20 ${isSidebarOpen ? 'md:hidden w-9/12 sm:w-6/12 md:w-60' : 'w-0 overflow-hidden'}`}
        // className="fixed top-0 left-0 z-40 h-screen transition-transform -translate-x-full print:hidden bg-primary w-60 pt-[104px] pb-24 sm:translate-x-0 pr-1"
        // className="fixed top-0 left-0 z-40 h-screen mt-2 transition-transform -translate-x-full pt-[134px] print:hidden bg-primary w-60 sm:translate-x-0"
        // className="fixed top-0 left-0 z-40 h-screen transition-transform -translate-x-full pt-28 print:hidden bg-primary w-60 sm:translate-x-0"
        aria-label="Sidebar"
      >
        <div
          id="sidebar-menu"
          className="h-full px-3 pb-4 overflow-y-auto bg-primary"
        >
          <Menu setIsSidebarOpen={setIsSidebarOpen} />
        </div>

        <div className="absolute bottom-12 flex items-center justify-center w-full m-auto px-3 h-12 border-t-2 border-secondary font-medium list-none">
          <Link className="w-full" href="/logout">
            <div className="flex items-center justify-between px-2 py-1 group border-s-2 text-white border-white hover:border-secondary hover:text-secondary focus:outline-none focus:border-secondary focus:text-secondary">
              <span className="text-lg uppercase font-notoExtraCondensedBlack ms-1">
                {t('sidebar.logout')}
              </span>
            </div>
          </Link>
        </div>

        <LogoGestinet
          parentClasses={
            'absolute bottom-0 flex items-center justify-center w-full m-auto h-12 border-t-2 border-secondary'
          }
          imgClasses={'w-20'}
          link={'https://www.gestinet.com'}
        />
      </aside>

      {/* Fondo oscuro para dispositivos con pantallas menores a 768px al abrir la barra lateral (móviles, tablets pequeñas) */}
      <div
        className={`${isSidebarOpen ? 'md:hidden' : 'hidden'} duration-1000 fixed z-10 w-screen h-screen bg-black bg-opacity-60`}
        onClick={() => {
          if (setIsSidebarOpen) {
            setIsSidebarOpen(false)
          }
        }}
      ></div>
    </>
  )
}

Sidebar.propTypes = {
  isSidebarOpen: PropTypes.bool,
  setIsSidebarOpen: PropTypes.func
}

import ColumnFilter from 'components/ui/Table/Filters/ColumnFilter'
import DateRangeColumnFilter from 'components/ui/Table/Filters/DateRangeColumnFilter'
import Table from 'components/ui/Table/Table'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ImSpinner9 } from 'react-icons/im'
import { LuTriangleAlert } from 'react-icons/lu'

export default function OrderCompGrid({ data, loading, error, handleClick }) {
  const [getTableData, setTableData] = useState(null)
  const { t, i18n } = useTranslation()

  function formatDate(dateString) {
    let formattedDate = null

    // Convertir fecha de rowA en un objeto 'Date'
    if (
      typeof dateString === 'string' &&
      dateString !== '' &&
      dateString !== null &&
      dateString !== undefined
    ) {
      const dateSplit = dateString.split('T')
      const parts = dateSplit[0].split('-')

      if (parts.length === 3) {
        let [year, month, day] = parts.map((num) => parseInt(num, 10))
        year = year < 100 ? 2000 + year : year
        formattedDate = new Date(Date.UTC(year, month - 1, day, 0, 0, 0))
        formattedDate = `${String(formattedDate.getUTCDate()).padStart(2, '0')}/${String(formattedDate.getUTCMonth() + 1).padStart(2, '0')}/${String(formattedDate.getUTCFullYear()).padStart(2, '0')}`
      }
    } else {
      formattedDate = t('no_date')
    }

    return formattedDate
  }

  // Ordenación personalizada para fechas
  const customDateSort = (rowA, rowB, columnId) => {
    let dateA = null
    let dateB = null

    // Convertir fecha de rowA en un objeto 'Date'
    if (
      typeof rowA?.values[columnId] === 'string' &&
      rowA?.values[columnId] !== '' &&
      rowA?.values[columnId] !== null &&
      rowA?.values[columnId] !== undefined
    ) {
      const parts = rowA?.values[columnId].split('/')

      if (parts.length === 3) {
        let [day, month, year] = parts.map((num) => parseInt(num, 10))
        year = year < 100 ? 2000 + year : year
        dateA = new Date(Date.UTC(year, month - 1, day, 0, 0, 0))
      }
    }

    // Convertir fecha de rowB en un objeto 'Date'
    if (
      typeof rowB?.values[columnId] === 'string' &&
      rowB?.values[columnId] !== '' &&
      rowB?.values[columnId] !== null &&
      rowB?.values[columnId] !== undefined
    ) {
      const parts = rowB?.values[columnId].split('/')

      if (parts.length === 3) {
        let [day, month, year] = parts.map((num) => parseInt(num, 10))
        year = year < 100 ? 2000 + year : year
        dateB = new Date(Date.UTC(year, month - 1, day, 0, 0, 0))
      }
    }

    // Comparar fechas
    if (isNaN(dateA)) return 1
    if (isNaN(dateB)) return -1

    return dateA - dateB
  }

  // Columnas:
  const columns = useMemo(
    () => [
      {
        Header: () => (
          <>
            {t('order.compGrid.num_order')}
            <br />
            Cliente
          </>
        ),
        TitleDescription: t('order.compGrid.num_order') + ' Cliente',
        accessor: 'Orders_Numero_Pedido_Cliente',
        Filter: ColumnFilter
      },
      {
        Header: () => (
          <>
            {t('order.compGrid.num_order')}
            <br />
            Techno
          </>
        ),
        TitleDescription: t('order.compGrid.num_order') + ' Technoform',
        accessor: 'Orders_Numero_Pedido_Technoform',
        Filter: ColumnFilter
      },
      {
        Header: () => <>{t('order.compGrid.position')}</>,
        TitleDescription: t('order.compGrid.position'),
        accessor: 'Orders_Posicion'
      },
      {
        Header: () => (
          <>
            {t('order.compGrid.reference')}
            <br />
            Cliente
          </>
        ),
        TitleDescription: t('order.compGrid.reference') + ' Cliente',
        accessor: 'Orders_Referencia_Cliente',
        Filter: ColumnFilter
      },
      {
        Header: () => (
          <>
            {t('order.compGrid.reference')}
            <br />
            Technoform
          </>
        ),
        TitleDescription: t('order.compGrid.reference') + ' Technoform',
        accessor: 'Orders_Referencia_Technoform',
        Filter: ColumnFilter
      },
      {
        Header: () => <>{t('order.compGrid.confection')}</>,
        TitleDescription: t('order.compGrid.confection'),
        accessor: 'Orders_Confeccion'
      },
      {
        Header: () => <>{t('order.compGrid.material')}</>,
        TitleDescription: t('order.compGrid.material'),
        accessor: 'Orders_Material'
      },
      {
        Header: () => <>{t('order.compGrid.meters')}</>,
        TitleDescription: t('order.compGrid.meters'),
        accessor: 'Orders_Metros'
      },
      {
        Header: () => <>{t('order.compGrid.length')}</>,
        TitleDescription: t('order.compGrid.length'),
        accessor: 'Orders_Longitud'
      },
      {
        Header: () => (
          <>
            {t('order.compGrid.rods')}
            <br />
            {t('order.compGrid.package')}
          </>
        ),
        TitleDescription:
          t('order.compGrid.rods') + ' ' + t('order.compGrid.package'),
        accessor: 'Orders_Varillas_Paquete'
      },
      {
        Header: () => (
          <>
            {t('order.compGrid.date')}
            <br />
            {t('order.compGrid.order')}
          </>
        ),
        TitleDescription:
          t('order.compGrid.date') + ' ' + t('order.compGrid.order'),
        accessor: 'Orders_Fecha_Pedido',
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween', // Filtro de fechas entre
        sortType: customDateSort // Función de ordenación personalizada para fechas
      },
      {
        Header: () => (
          <>
            {t('order.compGrid.date')}
            <br />
            {t('order.compGrid.delivery')}
          </>
        ),
        TitleDescription:
          t('order.compGrid.date') + ' ' + t('order.compGrid.delivery'),
        accessor: 'Orders_Fecha_Entrega',
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween', // Filtro de fechas entre
        sortType: customDateSort // Función de ordenación personalizada para fechas
      },
      {
        Header: () => (
          <>
            {t('order.compGrid.pallets')}
            <br />
            {t('order.compGrid.estimated')}
          </>
        ),
        TitleDescription: t('order.compGrid.estimated_pallets'),
        accessor: 'Orders_Cantidad_Estimada'
      },
      {
        Header: () => (
          <>
            {t('order.compGrid.production')}
            <br />
            {t('order.compGrid.foreign')}
          </>
        ),
        TitleDescription: t('order.compGrid.foreign_production'),
        accessor: 'Orders_Produccion_Extranjera'
      }
    ],
    []
  )

  // Adaptar datos para la tabla:
  useEffect(() => {
    if (data) {
      const itemsData = []

      data?.forEach((item) => {
        itemsData.push({
          Orders_Numero_Pedido_Cliente: item.Numero_pedido_Cliente,
          Orders_Numero_Pedido_Technoform: item.lineapedido_numeropedido,
          Orders_Posicion: item.Posicion
            ? new Intl.NumberFormat('de-DE').format(item.Posicion)
            : '',
          Orders_Referencia_Cliente: item.Referencia_Cliente,
          Orders_Referencia_Technoform: item.Referencia_Technoform,
          Orders_Confeccion: item.Confeccion,
          Orders_Material: item.Material
            ? item.Material?.replace(', dry impact resistant', '')
            : '',
          Orders_Metros: item.Metros
            ? new Intl.NumberFormat('de-DE').format(item.Metros)
            : '',
          Orders_Longitud: item.Longitud
            ? new Intl.NumberFormat('de-DE').format(item.Longitud)
            : '',
          Orders_Varillas_Paquete: item.Varillas_Paquete
            ? new Intl.NumberFormat('de-DE').format(item.Varillas_Paquete)
            : '',
          Orders_Fecha_Pedido: formatDate(item.Fecha_Pedido),
          Orders_Fecha_Entrega: formatDate(item.Fecha_Entrega),
          Orders_Cantidad_Estimada: item.Cantidad_Estimada,
          Orders_Produccion_Extranjera: item.Produccion_Extranjera
            ? t('common.yes')
            : t('common.no')
        })
      })

      setTableData(itemsData)
    }
  }, [data, i18n.language])

  return error ? (
    <div className="flex items-center content-center justify-center w-full h-full">
      <p
        className="flex flex-col items-center justify-center h-full font-bold text-2xl dark:text-[#F5F5F5] text-gray-700"
        title="Cargando..."
      >
        <LuTriangleAlert
          className="w-fit min-w-fit text-red-500 dark:text-red-400"
          size={64}
        />
        Error
      </p>
    </div>
  ) : getTableData && !loading ? (
    <Table
      tableKey={'Orders'}
      tableName={t('order.title')}
      columns={columns}
      data={getTableData}
      defaultSort={null}
    />
  ) : (
    <div className="flex items-center content-center justify-center w-full h-full">
      <p
        className="flex flex-col-reverse items-center justify-center h-full font-bold"
        title="Cargando..."
      >
        <ImSpinner9 size={70} className="animate-spin text-[#102092]" />
      </p>
    </div>
  )
}

OrderCompGrid.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  handleClick: PropTypes.func,
  value: PropTypes.any
}

import { useModal } from 'contexts/ModalContext'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { AiOutlineDelete, AiOutlineSave } from 'react-icons/ai'
import { BsEyeFill } from 'react-icons/bs'
import * as Yup from 'yup'

const UserFormDetail = ({ handleSubmit, handleDelete, fields, isNew }) => {
  // Contextos:
  const { modal, openModal } = useModal()

  // Estados:
  const [getShowPassword, setShowPassword] = useState(false)
  const [getShowNewPassword, setShowNewPassword] = useState(false)
  const [getShowRepeatNewPassword, setShowRepeatNewPassword] = useState(false)

  // Esquema validación formulario (POST):
  const validationSchemaPost = Yup.object().shape({
    Name: Yup.string()
      .nullable('(*)')
      .required('(*)')
      .min(2, '(* Muy corto)')
      .max(100, '(* Muy largo)'),
    Email: Yup.string()
      .nullable('(*)')
      .required('(*)')
      .email('(* Formato erróneo)')
      .min(2, '(*)')
      .max(100, '(*)'),
    UserName: Yup.string()
      .nullable('(*)')
      .required('(*)')
      .min(2, '(* Muy corto)')
      .max(100, '(* Muy largo)'),
    Password: Yup.string()
      .nullable('(*)')
      .required('(*)')
      .min(6, '(* Muy corta)')
      .max(25, '(* Muy larga)')
  })

  // Esquema validación formulario (PUT):
  const validationSchemaPut = Yup.object().shape({
    Name: Yup.string()
      .nullable()
      .required('(*)')
      .min(2, '(* Muy corto)')
      .max(100, '(* Muy largo)'),
    Email: Yup.string()
      .nullable()
      .required('(*)')
      .email('(* Formato erróneo)')
      .min(2, '(*)')
      .max(100, '(*)'),
    UserName: Yup.string()
      .nullable()
      .required('(*)')
      .min(2, '(* Muy corto)')
      .max(100, '(* Muy largo)'),
    NewPassword: Yup.string()
      .nullable()
      .min(6, '(* Muy corta)')
      .max(25, '(* Muy larga)')
      .oneOf(
        [Yup.ref('RepeatNewPassword')],
        '(* Las contraseñas no coinciden)'
      ),
    RepeatNewPassword: Yup.string()
      .nullable()
      .min(6, '(* Muy corta)')
      .max(25, '(* Muy larga)')
      .oneOf([Yup.ref('NewPassword')], '(* Las contraseñas no coinciden)')
  })

  // Modal confirmación eliminación:
  const handleDeleteModal = (id) => {
    const modalData = {
      id,
      handleDelete,
      type: 'DELETE'
    }

    openModal(modalData)
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...fields }}
        validationSchema={isNew ? validationSchemaPost : validationSchemaPut}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          setFieldValue,
          submitForm
        }) => (
          <Form className="flex flex-col" encType="multipart/form-data">
            <div className="grid grid-cols-1 auto-rows-auto p-4 m-0">
              {/* Primer contenedor => Campos: */}
              <div className="w-full grid grid-cols-2 gap-y-4 gap-x-3 pb-4 justify-between">
                {/* Campo 'Nombre': */}
                <div className="w-full bg-gray-200 py-2 px-3 items-center col-span-1">
                  <div className="flex flex-row items-center mb-3">
                    <label
                      className="block mr-2 text-blue-700 text-base font-ms-semi font-bold leading-4"
                      htmlFor="Name"
                    >
                      Nombre
                    </label>

                    <ErrorMessage
                      name="Name"
                      render={(message) => (
                        <span className="text-sm text-red-600 font-bold leading-4">
                          {message}
                        </span>
                      )}
                    />
                  </div>

                  <Field
                    className={`pl-2 rounded w-full appearance-none px-3 p-2 border-2 outline-none text-grey
                      ${
                        touched.Name && errors.Name
                          ? 'border-red-200 bg-red-50'
                          : 'border-gray-300'
                      }`}
                    name="Name"
                    type="text"
                  />
                </div>

                {/* Campo 'Correo': */}
                <div className="w-full bg-gray-200 py-2 px-3 items-center col-span-1">
                  <div className="flex flex-row items-center mb-3">
                    <label
                      className="block mr-2 text-blue-700 text-base font-ms-semi font-bold leading-4"
                      htmlFor="Email"
                    >
                      Correo
                    </label>

                    <ErrorMessage
                      name="Email"
                      render={(message) => (
                        <span className="text-sm text-red-600 font-bold leading-4">
                          {message}
                        </span>
                      )}
                    />
                  </div>

                  <Field
                    className={`pl-2 rounded w-full appearance-none px-3 p-2 border-2 outline-none text-grey
                      ${
                        touched.Email && errors.Email
                          ? 'border-red-200 bg-red-50'
                          : 'border-gray-300'
                      }`}
                    name="Email"
                    type="email"
                  />
                </div>

                {/* Campo 'Usuario': */}
                <div className="w-full bg-gray-200 py-2 px-3 items-center col-span-1">
                  <div className="flex flex-row items-center mb-3">
                    <label
                      className="block mr-2 text-blue-700 text-base font-ms-semi font-bold leading-4"
                      htmlFor="UserName"
                    >
                      Usuario
                    </label>

                    <ErrorMessage
                      name="UserName"
                      render={(message) => (
                        <span className="text-sm text-red-600 font-bold leading-4">
                          {message}
                        </span>
                      )}
                    />
                  </div>

                  <Field
                    className={`pl-2 rounded w-full appearance-none px-3 p-2 border-2 outline-none text-grey
                      ${
                        touched.UserName && errors.UserName
                          ? 'border-red-200 bg-red-50'
                          : 'border-gray-300'
                      }`}
                    name="UserName"
                    type="text"
                    autoComplete="new-password"
                  />
                </div>

                {/* Campo 'Contraseña': */}
                {isNew && (
                  <div className="w-full bg-gray-200 py-2 px-3 items-center col-span-1">
                    <div className="flex flex-row items-center mb-3">
                      <label
                        className="block mr-2 text-blue-700 text-base font-ms-semi font-bold leading-4"
                        htmlFor="Password"
                      >
                        Contraseña
                      </label>

                      <ErrorMessage
                        name="Password"
                        render={(message) => (
                          <span className="text-sm text-red-600 font-bold leading-4">
                            {message}
                          </span>
                        )}
                      />
                    </div>

                    <div className={`flex flex-row items-center`}>
                      <Field
                        className={`pl-2 rounded flex-grow appearance-none px-3 p-2 border-2 outline-none text-grey col-span-10
                          ${
                            touched.Password && errors.Password
                              ? 'border-red-200 bg-red-50'
                              : 'border-gray-300'
                          }`}
                        name="Password"
                        type={getShowPassword ? 'text' : 'password'}
                        autoComplete="new-password"
                      />

                      <div className="h-full w-24">
                        <button
                          className={`h-full w-3/4 ml-auto mr-0 p-1 transition duration-300 
                            font-ms-semi border-2 rounded 
                            flex flex-row print:hidden justify-center items-center
                            ${
                              getShowPassword
                                ? 'border-gray-400 bg-gray-300 text-gray-700'
                                : 'border-gray-700 bg-gray-600 text-white'
                            }`}
                          type="button"
                          alt="VER"
                          title="VER"
                          onClick={() =>
                            setShowPassword((prevValue) => !prevValue)
                          }
                        >
                          <BsEyeFill size={25} />
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* Segundo contenedor => Campos: */}
              {!isNew && (
                <div className="w-full pt-2 pb-4 px-3">
                  <div className="w-full grid grid-cols-1 justify-between border-2 border-dashed border-blue-300">
                    <div className="w-full bg-gray-200 py-3 px-3 items-center col-span-1">
                      <div className="flex flex-row items-center mb-3">
                        <label
                          className="block mr-2 text-blue-700 text-base font-ms-semi font-bold leading-4"
                          htmlFor="NewPassword"
                        >
                          Nueva contraseña
                        </label>

                        <ErrorMessage
                          name="NewPassword"
                          render={(message) => (
                            <span className="text-sm text-red-600 font-bold leading-4">
                              {message}
                            </span>
                          )}
                        />
                      </div>

                      <div className={`flex flex-row items-center`}>
                        <Field
                          className={`pl-2 rounded flex-grow appearance-none px-3 p-2 border-2 outline-none text-grey col-span-10
                            ${
                              touched.NewPassword && errors.NewPassword
                                ? 'border-red-200 bg-red-50'
                                : 'border-gray-300'
                            }`}
                          name="NewPassword"
                          type={getShowNewPassword ? 'text' : 'password'}
                          autoComplete="new-password"
                        />

                        <div className="h-full w-24">
                          <button
                            className={`h-full w-3/4 ml-auto mr-0 p-1 transition duration-300 
                              font-ms-semi border-2 rounded 
                              flex flex-row print:hidden justify-center items-center
                              ${
                                getShowNewPassword
                                  ? 'border-gray-400 bg-gray-300 text-gray-700'
                                  : 'border-gray-700 bg-gray-600 text-white'
                              }`}
                            type="button"
                            alt="VER"
                            title="VER"
                            onClick={() =>
                              setShowNewPassword((prevValue) => !prevValue)
                            }
                          >
                            <BsEyeFill size={25} />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="w-full bg-gray-200 py-3 px-3 items-center col-span-1">
                      <div className="flex flex-row items-center mb-3">
                        <label
                          className="block mr-2 text-blue-700 text-base font-ms-semi font-bold leading-4"
                          htmlFor="RepeatNewPassword"
                        >
                          Repetir contraseña
                        </label>

                        <ErrorMessage
                          name="RepeatNewPassword"
                          render={(message) => (
                            <span className="text-sm text-red-600 font-bold leading-4">
                              {message}
                            </span>
                          )}
                        />
                      </div>

                      <div className={`flex flex-row items-center`}>
                        <Field
                          className={`pl-2 rounded flex-grow appearance-none px-3 p-2 border-2 outline-none text-grey
                            ${
                              touched.RepeatNewPassword &&
                              errors.RepeatNewPassword
                                ? 'border-red-200 bg-red-50'
                                : 'border-gray-300'
                            }`}
                          name="RepeatNewPassword"
                          type={getShowRepeatNewPassword ? 'text' : 'password'}
                          autoComplete="new-password"
                        />

                        <div className="h-full w-24">
                          <button
                            className={`h-full w-3/4 ml-auto mr-0 p-1 transition duration-300 
                              font-ms-semi border-2 rounded 
                              flex flex-row print:hidden justify-center items-center
                              ${
                                getShowRepeatNewPassword
                                  ? 'border-gray-400 bg-gray-300 text-gray-700'
                                  : 'border-gray-700 bg-gray-600 text-white'
                              }`}
                            type="button"
                            alt="VER"
                            title="VER"
                            onClick={() =>
                              setShowRepeatNewPassword(
                                (prevValue) => !prevValue
                              )
                            }
                          >
                            <BsEyeFill size={25} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Tercer contenedor => Botones: */}
              <div className="w-full flex flex-row px-3 mt-2 justify-between">
                <div>
                  {/* Botón 'Eliminar': */}
                  {!isNew && (
                    <button
                      className={`transition duration-300 
                        border-red-700 bg-red-600 text-white 
                        hover:border-red-400
                        hover:bg-red-300 
                        hover:text-red-700
                        font-ms-semi py-3 px-10 my-2 border-2 rounded 
                        flex flex-row print:hidden`}
                      type="button"
                      onClick={(e) => {
                        handleDeleteModal(values.Id)
                      }}
                      alt="ELIMINAR"
                      title="ELIMINAR"
                    >
                      <AiOutlineDelete size={25} className="mr-2" />
                      <p className="font-semibold text-base">Eliminar</p>
                    </button>
                  )}
                </div>

                <div className="flex flex-row justify-end">
                  {/* Botón 'Guardar': */}
                  <button
                    className={`ml-5 transition duration-300 
                      border-green-700 bg-green-600 text-white 
                      hover:border-green-400
                      hover:bg-green-300 
                      hover:text-green-700
                      font-ms-semi py-3 px-10 my-2 border-2 rounded 
                      flex flex-row print:hidden`}
                    type="submit"
                    alt="GUARDAR"
                    title="GUARDAR"
                  >
                    <AiOutlineSave size={25} className="mr-2" />
                    <p className="font-semibold text-base">Guardar</p>
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

UserFormDetail.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  fields: PropTypes.object,
  isNew: PropTypes.bool,
  users: PropTypes.array,
  isUpdate: PropTypes.any,
  renderTableData: PropTypes.func
}

export default UserFormDetail

import PropTypes from 'prop-types'
import { LuTrash2, LuX } from 'react-icons/lu'

export default function DeleteModal({
  onClose,
  itemName,
  itemId,
  handleDelete
}) {
  return (
    <div
      className="fixed inset-0 z-10 overflow-y-auto bg-primary/75"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-4 text-center sm:items-end sm:block sm:p-0">
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-sm shadow-xl rtl:text-right dark:bg-gray-900 sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
          <div>
            <div className="flex items-center justify-center">
              <LuTrash2 className="text-red-600" size={26} />
            </div>

            <div className="mt-2 text-center">
              <h3
                className="text-lg font-medium leading-6 text-gray-800 dark:text-white"
                id="modal-title"
              >
                Està segur que vol eliminar el següent registre?
              </h3>
              <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                &apos;
                {itemName}&apos;
              </p>
            </div>
          </div>

          <div className="mt-5 sm:flex sm:items-center sm:justify-end">
            <div className="flex items-center justify-between gap-x-4">
              <button
                type="button"
                onClick={() => onClose()}
                className="flex items-center justify-center px-5 py-2 space-x-2 text-sm text-white transition-colors duration-200 bg-gray-500 border border-gray-500 rounded-sm w-fit dark:hover:bg-gray-800 enabled:hover:bg-white enabled:hover:text-gray-500 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:enabled:hover:bg-gray-800 disabled:opacity-30 focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-gray-500 focus:border-gray-500 dark:focus:border-gray-500"
              >
                <LuX size={20} />
                <span>Cancel·lar</span>
              </button>

              <button
                type="button"
                onClick={() => {
                  handleDelete(itemId)
                  onClose()
                }}
                className="flex items-center justify-center px-5 py-2 space-x-2 text-sm text-white transition-colors duration-200 bg-red-500 border border-red-500 rounded-sm w-fit dark:hover:bg-gray-800 enabled:hover:bg-white enabled:hover:text-red-500 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:enabled:hover:bg-gray-800 disabled:opacity-30 focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-red-500 focus:border-red-500 dark:focus:border-red-500"
              >
                <LuTrash2 size={20} />
                <span>Eliminar</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

DeleteModal.propTypes = {
  onClose: PropTypes.func,
  itemName: PropTypes.any,
  itemId: PropTypes.any,
  handleDelete: PropTypes.func
}

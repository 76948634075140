import LanguageSwitcher from 'components/ui/Language/LanguageSwitcher'
import { useUser } from 'contexts/UserContext'
import PropTypes from 'prop-types'
import { LuPanelLeftClose, LuPanelLeftOpen } from 'react-icons/lu'
import { Link } from 'wouter'

export default function Navbar({ isSidebarOpen, setIsSidebarOpen }) {
  const { user } = useUser()

  return (
    <nav className="fixed top-0 z-50 w-full bg-white border-b-8 border-primary h-20 md:h-[104px]">
      <div className="flex items-center justify-between w-full px-2 mx-auto lg:px-4 h-full">
        <div className="w-full">
          <div className="flex flex-row items-center justify-start m-0 space-x-2">
            <div className="md:hidden flex flex-row items-center justify-center m-0">
              {!isSidebarOpen ? (
                <LuPanelLeftOpen
                  className={`text-primary cursor-pointer hover:text-secondary`}
                  size={30}
                  onClick={() => setIsSidebarOpen((prev) => !prev)}
                />
              ) : (
                <LuPanelLeftClose
                  className={`text-primary cursor-pointer hover:text-secondary`}
                  size={30}
                  onClick={() => setIsSidebarOpen((prev) => !prev)}
                />
              )}
            </div>

            <span className="flex justify-center items-center text-primary text-5xl font-notoBold leading-none border-4 h-[60px] w-[60px] px-0 md:px-3 md:w-auto md:h-auto md:border-8 border-lg border-[#00c8bb] rounded-[12px]">
              T
            </span>

            <div className="flex flex-col items-end md:items-start grow md:flex-none">
              <div className="text-primary font-notoBlack text-sm md:text-base">
                {user?.Cliente || '?'}
              </div>

              <div className="text-primary font-notoBold text-sm md:text-base">
                {user.email}
              </div>

              <LanguageSwitcher />
            </div>
          </div>
        </div>

        <div className="hidden md:flex items-center justify-end h-24">
          <div className="flex items-center h-full mr-2">
            <Link href={'/orders/grid'} key="Logo" title="MiTECHNOFORM">
              <div className="flex items-center justify-center m-0 space-x-1">
                <span className="text-primary text-2xl md:text-5xl leading-none font-notoBold">
                  Mi
                </span>

                <span className="uppercase text-secondary text-2xl md:text-5xl font-notoBold leading-none">
                  Technoform
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  )
}

Navbar.propTypes = {
  isSidebarOpen: PropTypes.bool,
  setIsSidebarOpen: PropTypes.func
}

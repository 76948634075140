import { toast } from 'react-toastify'

export const useToast = () => {
  const showToast = (message, type = 'success') => {
    const formattedMessage =
      typeof message !== 'string' ? JSON.stringify(message) : message

    toast[type](formattedMessage, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      theme: 'light'
    })
  }

  return { showToast }
}

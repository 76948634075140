import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai'
import { Link } from 'wouter'
import SubMenuItems from './SubMenuItems'

export default function MenuItems({ item, user, location, setIsSidebarOpen }) {
  const { t } = useTranslation()

  // Estado para controlar el despliegue del submenú de cada ítem
  const [dropdown, setDropdown] = useState(false)

  // Estado para abrir automáticamente el submenú si coincide con la URL al cargar la página
  const [firstTimeAutoOpen, setFirstTimeAutoOpen] = useState(true)

  return (
    <>
      {/* Entramos si el menú es desplegable, si tiene submenú */}
      {item.subMenuItems ? (
        <>
          <li className="flex" title={t(item?.title)} key={item.title}>
            {/* 
              Explicación de las validaciones en 'className':
                - Se resalta el ítem principal si el usuario está en un ítem de submenú (ej. 'Plátano' dentro de 'Frutas').
                - Al abrir un submenú, se resalta el ítem principal para indicar que tiene submenú. 
                - Si la URL contiene el slug padre del submenú, se resalta el ítem principal desde donde se accedió (esto es útil cuando accedemos a un ítem de una lista y nos lleva a un formulario, permitiendo resaltar la página de origen).
                - Si el ítem está activo y no se cumplen las condiciones anteriores, se le agrega una animación de hover.
            */}
            <Link
              id={item.title}
              href={item.slug}
              onClick={() => {
                setDropdown((state) => !state)
              }}
              className={`w-full flex items-center justify-between px-2 py-1 group border-s-2 ${
                item?.subMenuItems.some(
                  (submenu) =>
                    submenu.slug === location ||
                    location.startsWith(submenu.parentSlug)
                ) || dropdown
                  ? '*:text-secondary border-secondary'
                  : item?.active &&
                    'border-white text-white hover:border-secondary hover:text-secondary'
              }`}
            >
              <span className="text-lg uppercase font-notoExtraCondensedBlack ms-1">
                {t(item.title)}
              </span>

              {item.subMenuItems &&
                (dropdown ? (
                  <AiFillCaretUp title="Ocultar submenú" />
                ) : (
                  <AiFillCaretDown title="Mostrar submenú" />
                ))}
            </Link>
          </li>

          <ul
            className={`space-y-2 font-medium list-none ${!dropdown && 'hidden'}`}
          >
            {item.subMenuItems.map((submenu, index) => {
              // Abrir el submenú automáticamente si la URL coincide con el submenú
              if (
                (location === submenu.slug ||
                  location.startsWith(submenu.parentSlug)) &&
                firstTimeAutoOpen
              ) {
                setDropdown(true)
                setFirstTimeAutoOpen(false)
              }

              return (
                <SubMenuItems
                  key={submenu.slug}
                  submenu={submenu}
                  location={location}
                  setIsSidebarOpen={setIsSidebarOpen}
                />
              )
            })}
          </ul>
        </>
      ) : (
        <li className="flex" title={t(item?.title)} key={item?.title}>
          {/* 
            Explicación de las validaciones en 'className':
              - Se resalta el ítem principal si el usuario está dentro.
              - Si la URL contiene el slug padre del submenú, se resalta el ítem principal desde donde se accedió (esto es útil cuando accedemos a un ítem de una lista y nos lleva a un formulario, permitiendo resaltar la página de origen).
              - Si el ítem está activo y no se cumplen las condiciones anteriores, se le agrega una animación de hover.
          */}
          <Link
            id={item.title}
            href={item.slug}
            className={`flex items-center justify-between px-2 py-1 group border-s-2 ${
              item?.slug === location || location.startsWith(item?.parentSlug)
                ? '*:text-secondary border-secondary'
                : item?.active &&
                  'border-white text-white hover:border-secondary hover:text-secondary'
            }`}
            onClick={() => setIsSidebarOpen && setIsSidebarOpen(false)}
          >
            <span className="text-lg uppercase font-notoExtraCondensedBlack ms-1">
              {t(item.title)}
            </span>
          </Link>
        </li>
      )}
    </>
  )
}

MenuItems.propTypes = {
  item: PropTypes.object,
  user: PropTypes.object,
  location: PropTypes.string,
  setIsSidebarOpen: PropTypes.func
}

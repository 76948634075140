import { useTitle } from 'contexts/TitleContext'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export default function ColumnFilter({ column }) {
  const { t } = useTranslation()
  const { filterValue, setFilter } = column
  const previousFilter = localStorage.getItem(column.id)
  const { title } = useTitle()

  useEffect(() => {
    localStorage.setItem(column.id, filterValue)
  }, [previousFilter, filterValue])

  useEffect(() => {
    if (previousFilter !== 'undefined') {
      setFilter(previousFilter)
    }
  }, [title])

  return (
    <div className="print:hidden">
      <input
        type="text"
        className={`w-full py-0.5 px-1 font-noto font-normal border border-gray-200 focus:ring-opacity-50 focus:outline-none focus:ring focus:border-gray-400 focus:ring-gray-300 hover:border-gray-300 rounded-sm text-gray-400 text-sm`}
        placeholder={t('common.filter')}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => setFilter(e.target.value)}
        value={filterValue || ''}
      />
    </div>
  )
}

ColumnFilter.propTypes = {
  column: PropTypes.object.isRequired
}

import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { LuTriangleAlert } from 'react-icons/lu'

export default function UwSimulatorFormDetail({
  data,
  selectedOpeningType,
  formData,
  setFormData
}) {
  const { t } = useTranslation()

  // Variables
  const rangeRef = useRef(null)
  const thumbRef = useRef(null)
  const thumbWidth = 100 // Ancho del thumb
  const customUgValues = [
    '0.8 W/m²K',
    '1.0 W/m²K',
    '1.2 W/m²K',
    '1.5 W/m²K',
    '1.8 W/m²K',
    '2.0 W/m²K'
  ]

  // Actualiza la posición del thumb en el slider
  useEffect(() => {
    const updateThumbPosition = () => {
      if (rangeRef.current && thumbRef.current) {
        const rangeWidth = rangeRef.current.offsetWidth // Ancho total del slider
        const xPX =
          (parseInt(formData?.ug, 10) * (rangeWidth - thumbWidth)) /
          (customUgValues.length - 1) // Ancho total del slider

        thumbRef.current.style.left = `${xPX}px` // Aplica la posición calculada
      }
    }

    updateThumbPosition()
    window.addEventListener('resize', updateThumbPosition) // Ajusta la posición del thumb cuando cambia el tamaño de la ventana

    return () => window.removeEventListener('resize', updateThumbPosition) //  Elimina el event listener cuando el componente se desmonta
  }, [formData?.ug])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log('Form Submitted:', formData)
  }

  return (
    <form onSubmit={(e) => handleSubmit(e)} className="flex flex-col space-y-7">
      <div className="flex flex-wrap items-end w-full gap-x-6 xl:gap-x-24 2xl:gap-x-40 gap-y-6">
        <div className="flex flex-row items-end gap-x-2">
          {/* Ancho */}
          <div className="flex flex-col">
            <label
              title={t('uw_simulator.viewDetail.width')}
              className="mb-2 text-primary font-notoBlack"
              htmlFor="width"
            >
              {t('uw_simulator.viewDetail.width')}
            </label>

            <input
              id="width"
              type="text"
              name="width"
              className="w-32 font-noto text-sm border text-primary border-primary px-1.5 h-8 outline-none rounded-sm disabled:opacity-30 disabled:cursor-not-allowed focus:ring-opacity-10 focus:outline-none focus:ring focus:border-primary focus:ring-primary"
              onChange={(e) => handleChange(e)}
              value={formData?.width}
            />
          </div>

          <span className="pb-1 text-primary font-notoBlack">x</span>

          {/* Alto */}
          <div className="flex flex-col">
            <label
              title={t('uw_simulator.viewDetail.height')}
              className="mb-2 text-primary font-notoBlack"
              htmlFor="height"
            >
              {t('uw_simulator.viewDetail.height')}
            </label>

            <div className="flex flex-row items-center space-x-2">
              <input
                id="height"
                type="text"
                name="height"
                className="w-32 font-noto text-sm border text-primary border-primary px-1.5 h-8 outline-none rounded-sm disabled:opacity-30 disabled:cursor-not-allowed focus:ring-opacity-10 focus:outline-none focus:ring focus:border-primary focus:ring-primary"
                onChange={(e) => handleChange(e)}
                value={formData?.height}
              />

              <span className="text-primary font-notoBlack">m</span>
            </div>
          </div>
        </div>

        {/* TODO: Ocultar en ciertos casos */}
        {/* Ancho vidrio derecho */}
        {selectedOpeningType?.Ancho_Vidrio_Derecho_Activo ? (
          <div className="flex flex-col">
            <label
              title={t('uw_simulator.viewDetail.right_glass_width')}
              className="mb-2 text-primary font-notoBlack"
              htmlFor="rightGlassWidth"
            >
              {t('uw_simulator.viewDetail.right_glass_width')}
            </label>

            <div className="flex flex-row items-center space-x-2">
              <input
                id="rightGlassWidth"
                type="text"
                name="rightGlassWidth"
                className="w-32 font-noto text-sm border text-primary border-primary px-1.5 h-8 outline-none rounded-sm disabled:opacity-30 disabled:cursor-not-allowed focus:ring-opacity-10 focus:outline-none focus:ring focus:border-primary focus:ring-primary"
                onChange={(e) => handleChange(e)}
                value={formData?.rightGlassWidth}
              />

              <span className="text-primary font-notoBlack">m</span>
            </div>
          </div>
        ) : null}
      </div>

      <div className="flex flex-wrap w-full gap-x-6 xl:gap-x-24 2xl:gap-x-40 gap-y-6">
        {/* Acristalamiento */}
        <div>
          <p
            title="Acristalamiento"
            className={`${!data?.Acristalamiento || data?.Acristalamiento?.length < 1 ? 'text-red-700' : 'text-primary'} font-notoBlack flex flex-row items-center space-x-2`}
          >
            <span>{t('uw_simulator.viewDetail.glazing')}</span>

            {!data?.Acristalamiento || data?.Acristalamiento?.length < 1 ? (
              <LuTriangleAlert
                className="text-red-500 w-fit min-w-fit dark:text-red-400"
                size={20}
              />
            ) : null}
          </p>

          <div
            className={`${!data?.Acristalamiento || data?.Acristalamiento?.length < 1 ? 'hidden' : 'flex'} flex-wrap mt-3`}
          >
            {data?.Acristalamiento?.map((glass, index) => {
              let translation = null
              let isUndefined = 0

              switch (glass?.Nombre) {
                case 'Doble':
                  translation = t(`uw_simulator.viewDetail.double_glass`)
                  break
                case 'Triple':
                  translation = t(`uw_simulator.viewDetail.triple_glass`)
                  break
                default:
                  isUndefined = 1
                  translation = t(`common.invalid_value`) // Todo: Revisar si está bien este valor por defecto
                  break
              }

              return (
                <div
                  key={index}
                  className={`flex items-center me-4 ${isUndefined || glass?.Activo ? 'opacity-40 cursor-not-allowed' : 'cursor-pointer'}`}
                >
                  <input
                    id={`glass-${index}`}
                    type="radio"
                    name="glazing"
                    className="outline-none"
                    onChange={(e) => handleChange(e)}
                    checked={formData?.glazing === glass?.Id}
                    value={glass?.Id}
                    disabled={isUndefined || glass?.Activo}
                  />

                  <label
                    htmlFor={`glass-${index}`}
                    className={`${isUndefined || glass?.Activo ? 'text-red-600 cursor-not-allowed' : 'text-primary cursor-pointer'} pb-0.5 text-sm font-noto ms-2`}
                  >
                    {translation}
                  </label>
                </div>
              )
            })}
          </div>

          <div
            className={`${!data?.Acristalamiento || data?.Acristalamiento?.length < 1 ? 'flex' : 'hidden'} justify-start items-start w-full mt-2`}
          >
            <div className="flex w-full">
              <p className="text-sm leading-none text-red-600 dark:text-red-500">
                {t('common.data_not_found')}
              </p>
            </div>
          </div>
        </div>

        {/* Tipo */}
        <div>
          <p
            title="Tipo"
            className={`${!data?.Tipo_Cristal || data?.Tipo_Cristal?.length < 1 ? 'text-red-700' : 'text-primary'} font-notoBlack flex flex-row items-center space-x-2`}
          >
            <span>{t('uw_simulator.viewDetail.type')}</span>

            {!data?.Tipo_Cristal || data?.Tipo_Cristal?.length < 1 ? (
              <LuTriangleAlert
                className="text-red-500 w-fit min-w-fit dark:text-red-400"
                size={20}
              />
            ) : null}
          </p>

          <div
            className={`${!data?.Tipo_Cristal || data?.Tipo_Cristal?.length < 1 ? 'hidden' : 'flex'} flex-wrap mt-3`}
          >
            {data?.Tipo_Cristal?.map((type, index) => {
              let translation = null
              let isUndefined = 0

              switch (type?.Nombre) {
                case 'Bajo emisivo':
                  translation = t(`uw_simulator.viewDetail.low_emissivity`)
                  break
                case 'Sin revestir':
                  translation = t(`uw_simulator.viewDetail.uncoated`)
                  break
                default:
                  isUndefined = 1
                  translation = t(`common.invalid_value`) // Todo: Revisar si está bien este valor por defecto
                  break
              }

              return (
                <div
                  key={index}
                  className={`flex items-center me-4 ${isUndefined || type?.Activo ? 'opacity-40 cursor-not-allowed' : 'cursor-pointer'}`}
                >
                  <input
                    id={`type-${index}`}
                    type="radio"
                    name="type"
                    className="outline-none"
                    onChange={(e) => handleChange(e)}
                    checked={formData?.type === type?.Id}
                    value={type?.Id}
                    disabled={isUndefined || type?.Activo}
                  />

                  <label
                    htmlFor={`type-${index}`}
                    className={`${isUndefined || type?.Activo ? 'text-red-600 cursor-not-allowed' : 'text-primary cursor-pointer'} pb-0.5 text-sm text-primary font-noto ms-2`}
                  >
                    {translation}
                  </label>
                </div>
              )
            })}
          </div>

          <div
            className={`${!data?.Tipo_Cristal || data?.Tipo_Cristal?.length < 1 ? 'flex' : 'hidden'} justify-start items-start w-full mt-2`}
          >
            <div className="flex w-full">
              <p className="text-sm leading-none text-red-600 dark:text-red-500">
                {t('common.data_not_found')}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Ug */}
      <div className="flex flex-col items-start justify-start w-full">
        <label
          title={t('uw_simulator.viewDetail.ug')}
          className="mb-2 text-primary font-notoBlack"
          htmlFor="ug"
        >
          {t('uw_simulator.viewDetail.ug')}
        </label>

        <div className="relative w-full xl:w-9/12 2xl:w-7/12 pt-1.5 pb-2">
          <input
            id="ug"
            ref={rangeRef}
            type="range"
            name="ug"
            className="w-full h-2 outline-none appearance-none range bg-secondary"
            min={0}
            max={customUgValues.length - 1}
            step={1}
            onChange={(e) => handleChange(e)}
            value={formData?.ug}
          />

          <span
            ref={thumbRef}
            className="range-thumb absolute font-notoBold top-0.5 left-0 flex items-center justify-center text-center text-primary text-sm pointer-events-none bg-white border-[3px] border-primary"
          >
            {customUgValues[formData?.ug]}
          </span>
        </div>
      </div>

      {/* Intercalario */}
      <div className="flex flex-col items-start justify-start w-full">
        <label
          title={t('uw_simulator.viewDetail.intercalary')}
          className={`${!data?.Intercalario || data?.Intercalario?.length < 1 ? 'text-red-700' : 'text-primary'} font-notoBlack flex flex-row items-center space-x-2`}
          htmlFor="intercalary"
        >
          <span>{t('uw_simulator.viewDetail.intercalary')}</span>

          {!data?.Intercalario || data?.Intercalario?.length < 1 ? (
            <LuTriangleAlert
              className="text-red-500 w-fit min-w-fit dark:text-red-400"
              size={20}
            />
          ) : null}
        </label>

        <div className="flex flex-wrap justify-between w-full gap-6 xl:w-9/12 2xl:w-7/12">
          <div className="mt-2">
            <select
              id="intercalary"
              name="intercalary"
              className={`${!data?.Intercalario || data?.Intercalario?.length < 1 ? 'hidden' : 'block'} w-56 font-noto text-sm border text-primary border-primary px-1.5 h-8 outline-none rounded-sm cursor-pointer disabled:opacity-30 disabled:cursor-not-allowed focus:ring-opacity-10 focus:outline-none focus:ring focus:border-primary focus:ring-primary`}
              onChange={(e) => handleChange(e)}
              value={formData?.intercalary}
            >
              <option value="">{t('common.select')}</option>

              {data?.Intercalario?.map((item, index) => (
                <option key={index} value={item.Id}>
                  {item.Nombre}
                </option>
              ))}
            </select>

            <div
              className={`${!data?.Intercalario || data?.Intercalario?.length < 1 ? 'flex' : 'hidden'} justify-start items-start w-full`}
            >
              <div className="flex w-full">
                <p className="text-sm leading-none text-red-600 dark:text-red-500">
                  {t('common.data_not_found')}
                </p>
              </div>
            </div>
          </div>

          <button
            title={t('uw_simulator.viewDetail.calculate')}
            type="submit"
            className="h-10 px-4 border-[3px] border-secondary rounded-[15px] font-notoExtraCondensedBlack text-primary transition-colors duration-150 outline-none bg-white enabled:hover:bg-secondary disabled:opacity-30 disabled:cursor-not-allowed"
            disabled
          >
            {t('uw_simulator.viewDetail.calculate')}
          </button>
        </div>
      </div>
    </form>
  )
}

UwSimulatorFormDetail.propTypes = {
  data: PropTypes.any,
  selectedOpeningType: PropTypes.any,
  formData: PropTypes.object,
  setFormData: PropTypes.func
}

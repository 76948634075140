import PropTypes from 'prop-types'
import Navbar from './Navbar/Navbar'
import Sidebar from './Sidebar/Sidebar'
import Title from './Title/Title'
import { useState } from 'react'

export default function Layout({ children }) {
  // useState()
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  return (
    <>
      <div className="fixed w-screen h-screen">
        <Navbar
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />

        <div className="fixed flex flex-row w-full h-full bg-white dark:bg-[#38393D] duration-100">
          <Sidebar
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />

          <div className="fixed flex flex-col w-full h-full pt-20 grow">
            <div className="md:pt-4 md:pl-60">
              <Title />
            </div>

            <div
              id="scrollable-parent"
              className={`relative w-full p-4 duration-100 overflow-y-auto overflow-x-hidden grow md:pl-64`}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

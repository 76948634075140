import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n.use(LanguageDetector).use(initReactI18next)
i18n.init({
  resources: {
    lng: 'es', // Idioma por defecto
    fallbackLng: 'es', // Idioma de respaldo si el detectado no está disponible
    es: {
      translation: {
        common: {
          yes: 'Sí',
          no: 'No',
          select: '--Seleccionar--',
          filter: 'Filtrar...',
          data_not_found: 'Datos no encontrados',
          no_data_available: 'Sin datos',
          undefined: 'Indefinido',
          invalid_value: 'Valor inválido'
        },
        sidebar: {
          logout: 'Cerrar Sesión'
        },
        menu_items: {
          logistics: 'Logística',
          contact: 'Contactar',
          technical_department: 'Dpt. Técnico'
        },
        submenu_items: {
          orders: 'Pedidos',
          uw_simulator: 'Simulador Uw'
        },
        table: {
          delete: 'Borrar',
          table_bar: {
            info_title: 'Sobre esta página',
            register: 'registros',
            export_excel: 'Exportar Excel',
            export_pdf: 'Exportar PDF',
            info_tooltip:
              'En esta página se presentan las líneas de pedidos realizadas en los últimos 24 meses. \n\n' +
              "En el campo 'Conf.' (Confección), las abreviaturas tienen los siguientes significados:" +
              '\n · N: Sin cordón termofusible ' +
              '\n · G: Con cordón termofusible ' +
              '\n · UB: Sin empaquetar ' +
              '\n · L: Marcado láser ' +
              '\n · WD: Draining ' +
              '\n · AF: Adhesive Film ' +
              '\n · PU: Punching ' +
              '\n · CP: Coating Prime ' +
              '\n · AP: Adhesion Prime'
          }
        },
        not_found: {
          error: '404 error',
          title: 'No podemos encontrar esa página.',
          subtitle:
            'Lo sentimos, la página que buscas no existe o ha sido movida.',
          button: 'Ir a la página de inicio'
        },
        user: {
          compGrid: {
            username: 'Usuario',
            name: 'Nombre'
          }
        },
        order: {
          title: 'Pedidos',
          compGrid: {
            num_order: 'Nº Pedido',
            position: 'Posición',
            reference: 'Referencia',
            confection: 'Confección',
            material: 'Material',
            meters: 'Metros',
            length: 'Longitud',
            rods: 'Varillas',
            package: 'Paquete',
            date: 'Fecha',
            order: 'Pedido',
            delivery: 'Entrega',
            no_date: 'No hay fecha',
            estimated: 'Estimados',
            pallets: 'Pallets',
            estimated_pallets: 'Cantidad Estimada de Pallets',
            foreign_production: 'Producción Extranjera',
            foreign: 'Extranjera',
            production: 'Producción'
          },
          table_bar: {
            order_client: 'Nº Pedido Cliente',
            order_techno: 'Nº Pedido Techno',
            position: 'Pos.',
            reference_client: 'Referencia Cliente',
            reference_techno: 'Referencia Technoform',
            confection: 'Conf.',
            material: 'Material',
            meters: 'Metros',
            length: 'Long.',
            rods_package: 'Varillas Paquete',
            order_date: 'Fecha Pedido',
            delivery_date: 'Fecha Entrega',
            estimated_pallets: 'Pallets Est.',
            foreign_production: 'P. Ext.'
          }
        },
        uw_simulator: {
          title: 'Simulador Uw',
          viewDetail: {
            practicable: 'Practicables',
            sliding: 'Correderas',
            doors: 'Puertas',
            series: 'Series',
            configurations: 'Configuración',
            loading_series: 'Cargando series...',
            opening_types: 'Tipo',
            width: 'Ancho',
            height: 'Alto',
            right_glass_width: 'Ancho vidrio derecho',
            glazing: 'Acristalamiento',
            double_glass: 'Vidrio doble',
            triple_glass: 'Vidrio triple',
            type: 'Tipo',
            uncoated: 'Sin revestir',
            low_emissivity: 'Bajo emisivo',
            ug: 'Ug',
            intercalary: 'Intercalario',
            calculate: 'Calcular',
            average_uf: 'Uf medio',
            uw: 'Uw',
            cte_zone: 'Zona CTE',
            download_pdf: 'Descargar PDF',
            loading_frames: 'Cargando marcos...'
          }
        }
      }
    },
    pt: {
      translation: {
        common: {
          yes: 'Sim',
          no: 'Não',
          select: '--Selecionar--',
          filter: 'Filtrar...',
          data_not_found: 'Dados não encontrados',
          no_data_available: 'Nenhum dado disponível',
          undefined: 'Indefinido',
          invalid_value: 'Valor inválido'
        },
        sidebar: {
          logout: 'Sair'
        },
        menu_items: {
          logistics: 'Logística',
          contact: 'Contato',
          technical_department: 'Depto. Técnico'
        },
        submenu_items: {
          orders: 'Encomendas',
          uw_simulator: 'Simulador Uw'
        },
        table: {
          delete: 'Limpar',
          table_bar: {
            info_title: 'Sobre esta página',
            register: 'registos',
            export_excel: 'Baixar Excel',
            export_pdf: 'Baixar PDF',
            info_tooltip:
              'Nesta página, são apresentadas as linhas de encomendas realizadas nos últimos 24 meses.\n\n' +
              "No campo 'Conf.' (Confeção), as abreviações têm os seguintes significados:\n" +
              '• N: sem cordão termo fusível\n' +
              '• G: com cordão termo fusível\n' +
              '• UB: sem empaquetado\n' +
              '• L: marcado laser\n' +
              '• WD: drenagem de água\n' +
              '• AF: Filme adesivo\n' +
              '• PU: punching\n' +
              '• CP: coating prime\n' +
              '• AP: adhesion prime'
          }
        },
        not_found: {
          error: 'Erro 404',
          title: 'Não conseguimos encontrar essa página.',
          subtitle:
            'Desculpe, a página que você procura não existe ou foi movida.',
          button: 'Ir para a página inicial'
        },
        user: {
          compGrid: {
            username: 'Usuário',
            name: 'Nome'
          }
        },
        order: {
          title: 'Encomendas',
          compGrid: {
            num_order: 'Encomenda',
            position: 'Pos.',
            reference: 'Referência',
            confection: 'Conf.',
            material: 'Material',
            meters: 'Metros',
            length: 'Compr.',
            rods: 'Varetas',
            package: 'Pacote',
            date: 'Data',
            order: 'Encomenda',
            delivery: 'Entrega',
            no_date: 'Não há data',
            estimated: 'Estimada',
            pallets: 'Pallets',
            estimated_pallets: 'Pallets Est.',
            foreign: 'Estrangeira',
            production: 'Produção',
            foreign_production: 'Produção Estrangeira'
          },
          table_bar: {
            order_client: 'Nº Pedido Cliente',
            order_techno: 'Nº Pedido Techno',
            position: 'Pos.',
            reference_client: 'Referência Cliente',
            reference_techno: 'Referência Technoform',
            confection: 'Conf.',
            material: 'Material',
            meters: 'Metros',
            length: 'Comp.',
            rods_package: 'Varetas Pacote',
            order_date: 'Data Pedido',
            delivery_date: 'Data Entrega',
            estimated_pallets: 'Pallets Est.',
            foreign_production: 'Prod. Ext.'
          }
        },
        uw_simulator: {
          title: 'Simulador Uw',
          viewDetail: {
            practicable: 'Praticáveis',
            sliding: 'Deslizantes',
            doors: 'Portas',
            series: 'Séries',
            configurations: 'Configuração',
            loading_series: 'Carregando séries...',
            opening_types: 'Tipo',
            width: 'Largura',
            height: 'Altura',
            right_glass_width: 'Largura do vidro direito',
            glazing: 'Envidraçamento',
            double_glass: 'Vidro duplo',
            triple_glass: 'Vidro triplo',
            type: 'Tipo',
            uncoated: 'Sem revestimento',
            low_emissivity: 'Baixa emissividade',
            ug: 'Ug',
            intercalary: 'Intercalário',
            calculate: 'Calcular',
            average_uf: 'Uf média',
            uw: 'Uw',
            cte_zone: 'Zona CTE',
            download_pdf: 'Baixar PDF',
            loading_frames: 'Carregando quadros...'
          }
        }
      }
    }
  }
})

export default i18n

import { useUser } from 'contexts/UserContext'
import { useEffect } from 'react'

export default function Logout() {
  // Contextos:
  const { setUser } = useUser()

  useEffect(() => {
    setUser(null)
    localStorage.clear()

    if (document.body.classList.contains('dark')) {
      document.body.classList.remove('dark')
    }
  }, [])

  return <></>
}

// PropTypes + HOOKS
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link } from 'wouter'

export default function SubMenuItems({ submenu, location, setIsSidebarOpen }) {
  const { t } = useTranslation()

  return (
    <li className="flex" title={t(submenu?.title)} key={submenu?.title}>
      {/* 
          Explicación de las validaciones en 'className':
            - Se resalta el ítem si el usuario está dentro.
            - Si la URL contiene el slug padre del submenú, se resalta el ítem desde donde se accedió (esto es útil cuando accedemos a un ítem de una lista y nos lleva a un formulario, permitiendo resaltar la página de origen).
            - Si el ítem está activo y no se cumplen las condiciones anteriores, se le agrega una animación de hover.
        */}
      <Link
        href={submenu.slug}
        className={`ml-4 flex items-center justify-between px-2 py-1 group border-s-2 text-sm  ${
          submenu?.slug === location || location.startsWith(submenu.parentSlug)
            ? '*:text-secondary border-secondary'
            : submenu?.active &&
              'border-white text-white hover:border-secondary hover:text-secondary'
        }`}
        onClick={() => setIsSidebarOpen && setIsSidebarOpen(false)}
      >
        <span className="text-lg uppercase font-notoExtraCondensedBlack ms-1">
          {t(submenu.title)}
        </span>
      </Link>
    </li>
  )
}

SubMenuItems.propTypes = {
  submenu: PropTypes.object,
  user: PropTypes.object,
  slug: PropTypes.string,
  location: PropTypes.any,
  setIsSidebarOpen: PropTypes.func
}

import PropTypes from 'prop-types'
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'

// Crea un contexto para gestionar el estado del modal
export const ModalContext = createContext(null)

// Proveedor del contexto del modal. Envuelve los componentes hijos y proporciona las funciones para manejar el modal
export const ModalProvider = ({ children }) => {
  // Estado para almacenar el modal abierto y sus propiedades
  const [modal, setModal] = useState(null)

  // Abre un modal con un nombre y propiedades opcionales
  const openModal = useCallback((name, props = {}) => {
    setModal({ name, props })
  }, [])

  // Cierra el modal estableciendo su estado en null
  const closeModal = useCallback(() => {
    setModal(null)
  }, [])

  const contextValue = useMemo(
    () => ({ modal, openModal, closeModal }),
    [modal, openModal, closeModal]
  )

  return (
    // Proporciona el estado y las funciones del modal a los componentes hijos
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  )
}

// Hook personalizado para usar el contexto del modal de forma más sencilla
export const useModal = () => useContext(ModalContext)

ModalProvider.propTypes = {
  children: PropTypes.any
}

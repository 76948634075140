import flecha from 'assets/img/imageInvert.png'
import { saveAs } from 'file-saver'
import JsPDF from 'jspdf'
import 'jspdf-autotable'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import XLSX from 'xlsx-js-style'

export default function OrdersTableBar({
  tableKey,
  tableName,
  rows,
  previousPage,
  canPreviousPage,
  pageIndex,
  pageOptions,
  nextPage,
  canNextPage,
  pageSize,
  setPageSize
}) {
  const { t } = useTranslation()

  // Exportar datos a Excel
  const handleExportExcel = () => {
    const columnMappings = getColumnMappings(tableKey)

    if (!columnMappings) {
      console.error('No column mapping found for table:', tableKey)
      return
    }

    // Convierte las filas de datos
    const worksheet = XLSX.utils.json_to_sheet(
      rows.map((row) => {
        const formattedRow = {}
        Object.keys(columnMappings).forEach((key) => {
          formattedRow[columnMappings[key]] = row.original[key] // Use mapped titles
        })
        return formattedRow
      })
    )

    // Ajusta el ancho de las columnas
    worksheet['!cols'] = Object.keys(columnMappings).map(() => ({ wch: 20 }))

    // Aplica estilos a la fila de encabezados
    const headerRange = XLSX.utils.decode_range(worksheet['!ref'])
    for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col }) // Dirección de la celda
      if (worksheet[cellAddress]) {
        worksheet[cellAddress].s = {
          fill: { fgColor: { rgb: '22C8BA' } }, // Fondo turquesa
          font: { bold: true, color: { rgb: 'FFFFFF' } }, // Texto blanco en negrita
          border: {
            top: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } },
            left: { style: 'thin', color: { rgb: '000000' } }
          },
          alignment: { horizontal: 'left', vertical: 'center' }
        }
      }
    }

    // Crea el archivo Excel
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, [tableName])

    // Genera el nombre del archivo con fecha y guarda el archivo
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    })
    const blobData = new Blob([excelBuffer], {
      type: 'application/octet-stream'
    })

    const fileDate = new Date()
    const year = fileDate.getFullYear()
    const month = (fileDate.getMonth() + 1).toString().padStart(2, '0')
    const day = fileDate.getDate().toString().padStart(2, '0')
    const hours = fileDate.getHours().toString().padStart(2, '0')
    const minutes = fileDate.getMinutes().toString().padStart(2, '0')
    const seconds = fileDate.getSeconds().toString().padStart(2, '0')

    saveAs(
      blobData,
      `${tableName}_${year}${month}${day}${hours}${minutes}${seconds}.xlsx`
    )
  }

  // Exportar registros a PDF
  const handlePDF = () => {
    const columnMappings = getColumnMappings(tableKey)

    if (!columnMappings) {
      console.error('No column mapping found for table:', tableKey)
      return
    }

    const doc = new JsPDF()

    // Extrae los encabezados
    const headers = Object.values(columnMappings)

    // Convierte los datos en el formato adecuado
    const body = rows.map((row) =>
      Object.keys(columnMappings).map((key) => row.original[key])
    )

    // Crea la tabla en el PDF
    doc.autoTable({
      head: [headers],
      body,
      styles: {
        fontSize: 8,
        cellPadding: 1,
        lineWidth: 0.1,
        lineColor: [0, 157, 150]
      },
      headStyles: {
        fillColor: [34, 200, 186], // #22C8BA
        lineWidth: 0.1,
        lineColor: [0, 157, 150]
      },
      margin: { top: 22, right: 10, bottom: 20, left: 10 },
      pageBreak: 'auto',
      rowPageBreak: 'avoid',
      alternateRowStyles: { fillColor: [221, 221, 221] },
      showHead: 'everyPage',
      showFoot: 'everyPage',
      didDrawPage: (data) => {
        const pageWidth = doc.internal.pageSize.width
        const pageHeight = doc.internal.pageSize.height

        // Pie de página
        doc.setFontSize(10)
        doc.setFont('helvetica', 'bold')
        doc.text(`${data.pageNumber}`, pageWidth / 2, pageHeight - 10, {
          align: 'center'
        })

        const headerText1 = 'Mi'
        const headerText2 = 'Technoform'

        const space = 18
        doc.setFontSize(24)

        // "Mi"
        doc.setTextColor(11, 31, 146) // #0b1f92
        const textWidth1 = doc.getTextWidth(headerText1)
        doc.text(
          headerText1,
          (pageWidth - textWidth1 - doc.getTextWidth(headerText2)) / 2,
          space
        )

        // "Technoform"
        doc.setTextColor(34, 200, 186) // #22C8BA
        const textWidth2 = doc.getTextWidth(headerText2)
        doc.text(
          headerText2,
          (pageWidth - textWidth2 - doc.getTextWidth(headerText1)) / 2 +
            textWidth1,
          space
        )
      }
    })

    // Genera el nombre del archivo con fecha y guarda el archivo
    const fileDate = new Date()
    const year = fileDate.getFullYear()
    const month = (fileDate.getMonth() + 1).toString().padStart(2, '0')
    const day = fileDate.getDate().toString().padStart(2, '0')
    const hours = fileDate.getHours().toString().padStart(2, '0')
    const minutes = fileDate.getMinutes().toString().padStart(2, '0')
    const seconds = fileDate.getSeconds().toString().padStart(2, '0')

    doc.save(
      `${tableName}_${year}${month}${day}${hours}${minutes}${seconds}.pdf`
    )
  }

  // Devuelve las columnas a exportar para una tabla específica
  const getColumnMappings = (tableKey) => {
    const mappings = {
      Orders: {
        Orders_Numero_Pedido_Cliente: t('order.table_bar.order_client'),
        Orders_Numero_Pedido_Technoform: t('order.table_bar.order_techno'),
        Orders_Posicion: t('order.table_bar.position'),
        Orders_Referencia_Cliente: t('order.table_bar.reference_client'),
        Orders_Referencia_Technoform: t('order.table_bar.reference_techno'),
        Orders_Confeccion: t('order.table_bar.confection'),
        Orders_Material: t('order.table_bar.material'),
        Orders_Metros: t('order.table_bar.meters'),
        Orders_Longitud: t('order.table_bar.length'),
        Orders_Varillas_Paquete: t('order.table_bar.rods_package'),
        Orders_Fecha_Pedido: t('order.table_bar.order_date'),
        Orders_Fecha_Entrega: t('order.table_bar.delivery_date'),
        Orders_Cantidad_Estimada: t('order.table_bar.estimated_pallets'),
        Orders_Produccion_Extranjera: t('order.table_bar.foreign_production')
      }
    }

    return mappings[tableKey] || {}
  }

  return (
    <div className="flex items-start justify-between px-2 pt-2 text-center bg-white print:hidden">
      <div
        className="flex items-center justify-center float-left space-x-2 text-left cursor-help select-none font-notoBold"
        title={t('table.table_bar.info_tooltip')}
      >
        <div
          className="text-primary rounded-full w-8 h-8 text-2xl flex justify-center items-center"
          style={{
            border: '3px solid #00c8bb'
          }}
        >
          <span>i</span>
        </div>

        <div className="relative inline-block tooltip text-primary text-sm">
          {t('table.table_bar.info_title')}
        </div>
      </div>

      <div className="flex flex-col items-center justify-center space-y-2 text-sm font-noto">
        <div className="flex flex-row items-center justify-center">
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="font-bold text-white rounded-full bg-secondary disabled:opacity-0"
            style={{ width: '24px', marginRight: '7px', height: '24px' }}
          >
            <img
              src={flecha}
              width="15"
              height="15"
              className="p-px"
              style={{ transform: 'rotate(180deg)', margin: 'auto' }}
            />
          </button>

          <span>
            Página <strong>{pageIndex + 1}</strong> de{' '}
            <strong>
              {pageOptions.length} {' ('}
              {rows.length} {t('table.table_bar.register')}
              {')'}
            </strong>
          </span>

          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="font-bold text-white rounded-full bg-secondary disabled:opacity-0"
            style={{ width: '24px', marginLeft: '7px', height: '24px' }}
          >
            <img
              src={flecha}
              width="15"
              height="15"
              className="p-px"
              style={{ margin: 'auto' }}
            />
          </button>
        </div>

        <select
          style={{
            border: '3px solid #00c8bb',
            borderRadius: '15px'
          }}
          className="float-right h-10 px-3 outline-none cursor-pointer"
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {[25, 75, 150, 250, 500].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize} {t('table.table_bar.register')}
            </option>
          ))}
        </select>
      </div>

      <div className="flex items-center justify-center float-left space-x-2 font-bold text-left">
        <button
          type="button"
          className="h-10 px-4 font-notoExtraCondensedBlack text-primary transition-colors duration-150 outline-none bg-white hover:bg-secondary"
          onClick={() => handleExportExcel()}
          disabled={!rows?.length}
          hidden={!rows?.length}
          style={{
            border: '3px solid #00c8bb',
            borderRadius: '15px'
          }}
        >
          {t('table.table_bar.export_excel')}
        </button>

        <button
          type="button"
          className="h-10 px-4 font-notoExtraCondensedBlack text-primary transition-colors duration-150 outline-none bg-white hover:bg-secondary"
          onClick={() => handlePDF()}
          disabled={!rows?.length}
          hidden={!rows?.length}
          style={{
            border: '3px solid #00c8bb',
            borderRadius: '15px'
          }}
        >
          {t('table.table_bar.export_pdf')}
        </button>
      </div>
    </div>
  )
}

OrdersTableBar.propTypes = {
  tableKey: PropTypes.string.isRequired,
  tableName: PropTypes.string.isRequired,
  rows: PropTypes.any.isRequired,
  previousPage: PropTypes.func,
  canPreviousPage: PropTypes.bool,
  pageIndex: PropTypes.number,
  pageOptions: PropTypes.array,
  nextPage: PropTypes.func,
  canNextPage: PropTypes.bool,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func
}

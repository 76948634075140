import { useTitle } from 'contexts/TitleContext'
import { useUser } from 'contexts/UserContext'
import UwSimulatorFormDetail from 'forms/TechnicalDepartment/UwSimulatorFormDetail'
import useFetch from 'hooks/useFetch'
import { useToast } from 'hooks/useToast'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ImSpinner9 } from 'react-icons/im'
import { LuTriangleAlert } from 'react-icons/lu'

export default function UwSimulatorViewDetail() {
  const { user } = useUser()
  const { setTitle } = useTitle()
  const { t } = useTranslation()
  const { showToast } = useToast()

  // useFetch(): hook personalizado para realizar solicitudes GET, POST, PUT, DELETE
  const [fetchedSeries, fetchSeries] = useFetch()
  const [fetchedConfigurations, fetchConfigurations] = useFetch()
  const [fetchedOpeningTypes, fetchOpeningTypes] = useFetch()
  const [fetchedFrames, fetchFrames] = useFetch()

  // useState()
  const [selectedWindowType, selectWindowType] = useState(null) // Tipo de ventana
  const [selectedSerie, selectSerie] = useState(null) // Serie
  const [selectedSerieImage, selectSerieImage] = useState(null) // Imagen de la serie seleccionada
  const [selectedConfiguration, selectConfiguration] = useState(null) // Configuración
  const [selectedOpeningType, selectOpeningType] = useState(null) // Tipo de apertura
  const [selectedHeight, selectHeight] = useState(null) // Ancho
  const [selectedWidth, selectWidth] = useState(null) // Alto
  const [selectedRightGlassWidth, selectRightGlassWidth] = useState(null) // Ancho vidrio derecho
  const [selectedFrames, selectFrames] = useState(null) // Marcos

  // Variables
  const windowTypes = [
    { label: t('uw_simulator.viewDetail.practicable'), value: 'Practicable' },
    { label: t('uw_simulator.viewDetail.sliding'), value: 'Corredera' },
    { label: t('uw_simulator.viewDetail.doors'), value: 'Puerta' }
  ]

  const [formData, setFormData] = useState({
    width: '',
    height: '',
    rightGlassWidth: '',
    glazing: '',
    type: '',
    intercalary: '',
    ug: 0
  })

  useEffect(() => {
    if (user) {
      setTitle({ name: t('uw_simulator.title') })
    }
  }, [])

  // Todo: Poner mensaje de error por defecto
  // [SERIES] CRUD: GET
  const handleSerie = async (windowType) => {
    selectWindowType(windowType)
    selectSerie(null)
    selectSerieImage(null)
    selectConfiguration(null)
    selectOpeningType(null)
    setFormData({
      width: '',
      height: '',
      rightGlassWidth: '',
      glazing: '',
      type: '',
      intercalary: '',
      ug: 0
    })

    const apiCallGet = {
      url: 'uw-simulator/series',
      method: 'POST',
      body: {
        AccessToken: user.AccessToken,
        Serie: windowType
      }
    }
    await fetchSeries(apiCallGet)
  }

  // Todo: Poner mensaje de error por defecto
  // [CONFIGURATIONS] CRUD: GET
  const handleConfiguration = async (serieId) => {
    selectConfiguration(null)
    selectOpeningType(null)
    setFormData({
      width: '',
      height: '',
      rightGlassWidth: '',
      glazing: '',
      type: '',
      intercalary: '',
      ug: 0
    })

    if (serieId) {
      const apiCallGet = {
        url: 'uw-simulator/configurations',
        method: 'POST',
        body: {
          AccessToken: user.AccessToken,
          IdSistema: serieId
        }
      }
      await fetchConfigurations(apiCallGet)
    }
  }

  // Todo: Poner mensaje de error por defecto
  // [OPENING TYPES] CRUD: GET
  const handleOpeningTypes = async (configurationId) => {
    selectOpeningType(null)
    setFormData({
      width: '',
      height: '',
      rightGlassWidth: '',
      glazing: '',
      type: '',
      intercalary: '',
      ug: 0
    })

    if (configurationId) {
      const apiCallGet = {
        url: 'uw-simulator/opening-types',
        method: 'POST',
        body: {
          AccessToken: user.AccessToken,
          IdSubSistema: configurationId,
          Serie: selectedWindowType
        }
      }
      await fetchOpeningTypes(apiCallGet)
    }
  }

  // Todo: Poner mensaje de error por defecto
  // [FRAMES] CRUD: GET
  const handleFrames = async (openingType) => {
    setFormData({
      width: '',
      height: '',
      rightGlassWidth: '',
      glazing: '',
      type: '',
      intercalary: '',
      ug: 0
    })

    if (openingType) {
      const apiCallGet = {
        url: 'uw-simulator/frames',
        method: 'POST',
        body: {
          AccessToken: user.AccessToken,
          Tipo_Obertura: {
            Id: openingType?.Id,
            TipoCalculo: openingType?.TipoCalculo,
            Nombre: openingType?.Nombre
          },
          bf: fetchedOpeningTypes?.data?.data?.bf,
          Uf: fetchedOpeningTypes?.data?.data?.Uf
        }
      }
      await fetchFrames(apiCallGet)
    }
  }

  // [SERIES] Mostrar mensaje API: POST (notificación de error)
  useEffect(() => {
    if (fetchedSeries?.error) {
      showToast(fetchedSeries?.error, 'error')
    }
  }, [fetchedSeries?.data, fetchedSeries?.error])

  // [CONFIGURATIONS] Mostrar mensaje API: POST (notificación de error)
  useEffect(() => {
    if (fetchedConfigurations?.error) {
      showToast(fetchedConfigurations?.error, 'error')
    }
  }, [fetchedConfigurations?.data, fetchedConfigurations?.error])

  // [OPENING TYPES] Mostrar mensaje API: POST (notificación de error)
  useEffect(() => {
    if (fetchedOpeningTypes?.error) {
      showToast(fetchedOpeningTypes?.error, 'error')
    }
  }, [fetchedOpeningTypes?.data, fetchedOpeningTypes?.error])

  // [FRAMES] Mostrar mensaje API: POST (notificación de error)
  useEffect(() => {
    if (fetchedFrames?.error) {
      showToast(fetchedFrames?.error, 'error')
    }
  }, [fetchedFrames?.data, fetchedFrames?.error])

  return (
    <>
      <div className="w-full space-y-6">
        {/* Tipo de ventaba */}
        <div className="flex flex-row space-x-24">
          {windowTypes.map((windowType) => (
            <button
              key={windowType.label}
              type="button"
              className={`w-40 text-left px-2.5 py-0.5 font-notoBlack text-primary transition-colors duration-150 outline-none bg-white border-[3px] enabled:hover:border-secondary rounded-[15px] disabled:opacity-30 disabled:cursor-not-allowed focus:ring-opacity-20 focus:outline-none focus:ring focus:ring-secondary focus:border-secondary ${selectedWindowType === windowType.value ? 'border-secondary' : ' border-transparent'}`}
              onClick={() => handleSerie(windowType.value)}
              disabled={fetchedSeries?.loading}
            >
              {windowType.label}
            </button>
          ))}
        </div>

        <div className="flex flex-row px-3">
          {fetchedSeries?.error ? (
            <div className="flex items-start justify-start w-full col-span-12">
              <div className="flex flex-row w-full space-x-2">
                <p className="mt-1 leading-none text-red-600 dark:text-red-500 font-notoBlack">
                  {fetchedSeries?.error || '\u00A0'}
                </p>

                <LuTriangleAlert
                  className="text-red-500 dark:text-red-400"
                  size={20}
                />
              </div>
            </div>
          ) : fetchedSeries?.loading ? (
            <div className="col-span-12">
              <div className="flex flex-row w-full space-x-2">
                <p className="mt-1 leading-none text-[#102092] font-notoBlack">
                  {t('uw_simulator.viewDetail.loading_series')}
                </p>

                <ImSpinner9
                  title={t('uw_simulator.viewDetail.loading_series')}
                  size={20}
                  className="animate-spin text-[#102092] dark:text-[#F5F5F5]"
                />
              </div>
            </div>
          ) : (
            <>
              {/* Columna izquierda */}
              <div className="w-full lg:w-7/12 2xl:w-8/12 space-y-7">
                {/* Serie */}
                {fetchedSeries?.data?.data ? (
                  <div>
                    <label
                      title="Rol"
                      className="text-primary font-notoBlack"
                      htmlFor="serie"
                    >
                      {t('uw_simulator.viewDetail.series')}
                    </label>

                    <div className="flex flex-row mt-2 pb-0.5">
                      <select
                        id="serie"
                        className="w-56 font-noto text-sm border text-primary border-primary px-1.5 h-8 outline-none rounded-sm cursor-pointer disabled:opacity-30 disabled:cursor-not-allowed focus:ring-opacity-10 focus:outline-none focus:ring focus:border-primary focus:ring-primary"
                        onChange={(e) => {
                          const selectedIndex = e.target.value
                          const selectedData =
                            fetchedSeries?.data?.data?.[selectedIndex] || null

                          selectSerie(selectedData?.Nombre || null)
                          selectSerieImage(selectedData?.Url_Imagen || null)
                          handleConfiguration(selectedData?.Id)
                        }}
                      >
                        <option value="">{t('common.select')}</option>

                        {fetchedSeries?.data?.data?.map((serie, index) => (
                          <option key={index} value={index}>
                            {serie.Nombre}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                ) : null}

                {/* Configuración */}
                {selectedSerie ? (
                  <div>
                    <div
                      title={t('uw_simulator.viewDetail.configurations')}
                      className={`${fetchedConfigurations?.error ? 'text-red-700' : 'text-primary'} font-notoBlack flex flex-row items-center space-x-2`}
                    >
                      <span>{t('uw_simulator.viewDetail.configurations')}</span>

                      {fetchedConfigurations?.error ? (
                        <LuTriangleAlert
                          className="text-red-500 w-fit min-w-fit dark:text-red-400"
                          size={20}
                        />
                      ) : fetchedConfigurations?.loading ? (
                        <ImSpinner9
                          size={20}
                          className="animate-spin text-[#102092]"
                        />
                      ) : null}
                    </div>

                    <div
                      className={`${fetchedConfigurations?.error ? 'hidden' : 'flex'} flex-wrap gap-6 mt-3`}
                    >
                      {fetchedConfigurations?.data?.data?.map(
                        (configuration, index) => (
                          <div
                            key={index}
                            className="flex flex-col items-center w-[88px] space-y-1"
                          >
                            <img
                              data-id={configuration?.Id} // Campo dataset personalizado para poner una variable
                              tabIndex="0"
                              title={configuration?.Nombre}
                              alt={configuration?.Nombre}
                              loading="lazy"
                              src={configuration?.Url_Imagen}
                              onClick={(e) => {
                                selectConfiguration(e.target.dataset.id)
                                handleOpeningTypes(e.target.dataset.id)
                              }}
                              className={`object-contain w-[88px] h-20 rounded-sm cursor-pointer ${selectedConfiguration === configuration?.Id ? 'border-2 border-secondary ring-opacity-10 outline-none ring ring-secondary' : 'border border-primary focus:ring-opacity-10 focus:outline-none focus:ring focus:border-secondary focus:ring-secondary'}`}
                            />

                            <span className="text-sm text-center font-noto text-primary">
                              {configuration?.Nombre}
                            </span>
                          </div>
                        )
                      )}
                    </div>

                    <div
                      className={`${fetchedConfigurations?.error ? 'flex' : 'hidden'} justify-start items-start w-full mt-2`}
                    >
                      <div className="flex w-full">
                        <p className="text-sm leading-none text-red-600 dark:text-red-500">
                          {fetchedConfigurations?.error || '\u00A0'}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}

                {/* Tipo de apertura */}
                {selectedConfiguration ? (
                  <div>
                    <div
                      title={t('uw_simulator.viewDetail.opening_types')}
                      className={`${fetchedOpeningTypes?.error ? 'text-red-700' : 'text-primary'} font-notoBlack flex flex-row items-center space-x-2`}
                    >
                      <span>{t('uw_simulator.viewDetail.opening_types')}</span>

                      {fetchedOpeningTypes?.error ? (
                        <LuTriangleAlert
                          className="text-red-500 w-fit min-w-fit dark:text-red-400"
                          size={20}
                        />
                      ) : fetchedOpeningTypes?.loading ? (
                        <ImSpinner9
                          size={20}
                          className="animate-spin text-[#102092]"
                        />
                      ) : null}
                    </div>

                    <div
                      className={`${fetchedOpeningTypes?.error ? 'hidden' : 'flex'} flex-wrap gap-6 mt-3`}
                    >
                      {fetchedOpeningTypes?.data?.data?.Tipo_Obertura?.map(
                        (openingType, index) => (
                          <div
                            key={index}
                            className="flex flex-col items-center space-y-1 w-28"
                          >
                            <img
                              data-id={openingType?.Id} // Campo dataset personalizado para poner una variable
                              tabIndex="0"
                              title={openingType?.Nombre}
                              alt={openingType?.Nombre}
                              loading="lazy"
                              src={openingType?.Url_Imagen}
                              onClick={(e) => {
                                selectOpeningType(openingType)
                                setFormData({
                                  width: '',
                                  height: '',
                                  rightGlassWidth: '',
                                  glazing: '',
                                  type: '',
                                  intercalary: '',
                                  ug: 0
                                })
                                handleFrames(openingType)
                              }}
                              className={`object-contain w-28 rounded-sm cursor-pointer h-24 ${selectedOpeningType?.Id === openingType?.Id ? 'border-2 border-secondary ring-opacity-10 outline-none ring ring-secondary' : 'border border-transparent'}`}
                            />

                            <span className="text-sm text-center font-noto text-primary">
                              {openingType?.Nombre}
                            </span>
                          </div>
                        )
                      )}
                    </div>

                    <div
                      className={`${fetchedOpeningTypes?.error ? 'flex' : 'hidden'} flex justify-start items-start w-full mt-2`}
                    >
                      <div className="flex w-full">
                        <p className="text-sm leading-none text-red-600 dark:text-red-500">
                          {fetchedOpeningTypes?.error || '\u00A0'}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}

                {selectedOpeningType && !fetchedFrames?.error ? (
                  <UwSimulatorFormDetail
                    data={fetchedOpeningTypes?.data?.data}
                    selectedOpeningType={selectedOpeningType}
                    formData={formData}
                    setFormData={setFormData}
                  />
                ) : null}
              </div>

              {/* Columna derecha */}
              <div className="w-full space-y-2 lg:w-5/12 2xl:w-4/12">
                {/* Imagen de la serie */}
                {fetchedSeries?.data?.data && selectedSerieImage ? (
                  <div className="flex items-center justify-end w-full">
                    <img
                      title={selectedSerie}
                      alt={selectedSerie}
                      loading="lazy"
                      src={selectedSerieImage}
                      className="object-contain border-none rounded-sm outline-none h-80"
                    />
                  </div>
                ) : null}

                {/* TODO: Acabar de revisar que no se muestre si falla */}
                {/* bf / uf */}
                {fetchedFrames?.error ? (
                  <div className="flex flex-col justify-end w-full gap-2 ml-auto mr-0 lg:w-11/12 xl:w-8/12">
                    <div className="flex flex-row items-center gap-x-1">
                      <span className="text-sm leading-none text-red-600 grow dark:text-red-500">
                        {fetchedFrames?.error || '\u00A0'}
                      </span>

                      <span className="w-20 text-red-600 font-notoBlack">
                        bf
                      </span>

                      <span className="w-20 text-red-600 font-notoBlack">
                        uf
                      </span>
                    </div>
                  </div>
                ) : fetchedFrames?.loading ? (
                  <div className="flex flex-row items-center justify-end w-full gap-2 ml-auto mr-0 lg:w-11/12 xl:w-8/12">
                    <p className="mt-1 leading-none text-[#102092] font-notoBlack">
                      {t('uw_simulator.viewDetail.loading_frames')}
                    </p>

                    <ImSpinner9
                      title={t('uw_simulator.viewDetail.loading_frames')}
                      size={20}
                      className="animate-spin text-[#102092] dark:text-[#F5F5F5]"
                    />
                  </div>
                ) : selectedOpeningType &&
                  fetchedFrames?.data?.data?.Marco ? (
                  <div className="flex flex-col justify-end w-full gap-2 ml-auto mr-0 lg:w-11/12 xl:w-8/12">
                    <div className="flex flex-row gap-x-1">
                      <span className="flex justify-start py-1 text-xs grow text-secondary font-noto">
                        {'\u00A0'}
                      </span>

                      <span className="w-20 text-primary font-notoBlack">
                        bf
                      </span>

                      <span className="w-20 text-primary font-notoBlack">
                        uf
                      </span>
                    </div>

                    {fetchedFrames?.data?.data?.Marco?.map(
                      (frame, index) => (
                        <div key={index} className="flex flex-row gap-x-1">
                          <span className="flex justify-start py-1 text-xs grow text-secondary font-noto">
                            {frame?.Nombre}
                          </span>

                          <span
                            title={frame['UF Titulo']}
                            className="flex w-20 px-1.5 py-0.5 text-xs border-2 rounded-sm text-primary font-noto border-secondary bg-white"
                          >
                            {frame?.bf}
                          </span>

                          <span
                            title={frame['BF Titulo']}
                            className="flex w-20 px-1.5 py-0.5 text-xs border-2 rounded-sm text-primary font-noto border-secondary bg-white"
                          >
                            {frame?.Uf}
                          </span>
                        </div>
                      )
                    )}
                  </div>
                ) : null}

                {/* {selectedOpeningType &&
                fetchedFrames?.data?.data?.Configuracion ? (
                  <div className="flex flex-col justify-end w-full gap-2 ml-auto mr-0 lg:w-11/12 xl:w-8/12">
                    <div className="flex flex-row gap-x-1">
                      <span className="flex justify-start py-1 text-xs grow text-secondary font-noto">
                        {'\u00A0'}
                      </span>

                      <span className="w-20 text-primary font-notoBlack">
                        bf
                      </span>

                      <span className="w-20 text-primary font-notoBlack">
                        uf
                      </span>
                    </div>

                    {fetchedFrames?.data?.data?.ConfiguracionfetchedFrames?.data?.data?.Configuracion?.map(
                      (frame, index) => (
                        <div key={index} className="flex flex-row gap-x-1">
                          <span className="flex justify-start py-1 text-xs grow text-secondary font-noto">
                            {frame?.Titulo}
                          </span>

                          <span
                            title={frame['UF Titulo']}
                            className="flex w-20 px-1.5 py-0.5 text-xs border-2 rounded-sm text-primary font-noto border-secondary bg-white"
                          >
                            {frame?.BF}
                          </span>

                          <span
                            title={frame['BF Titulo']}
                            className="flex w-20 px-1.5 py-0.5 text-xs border-2 rounded-sm text-primary font-noto border-secondary bg-white"
                          >
                            {frame?.UF}
                          </span>
                        </div>
                      )
                    )}
                  </div>
                ) : null} */}

                {/* Uf media */}
                {/* <div className="flex flex-wrap items-center justify-end w-full gap-2">
                  <label
                    title={t('uw_simulator.viewDetail.average_uf')}
                    className="text-primary font-notoBlack"
                    htmlFor="average_uf"
                  >
                    {t('uw_simulator.viewDetail.average_uf')}
                  </label>

                  <div className="flex flex-row items-center space-x-4">
                    <div className="w-[10.25rem] font-noto text-sm border-2 text-secondary border-secondary px-1.5 h-8 rounded-sm bg-white">
                      {'\u00A0'}
                    </div>
                  </div>
                </div> */}

                {/* Uw */}
                {/* <div className="flex flex-wrap items-center justify-end w-full gap-2">
                  <label
                    title={t('uw_simulator.viewDetail.uw')}
                    className="text-primary font-notoBlack"
                    htmlFor="uw"
                  >
                    {t('uw_simulator.viewDetail.uw')}
                  </label>

                  <div className="flex flex-row items-center space-x-4">
                    <div className="w-[10.25rem] font-noto text-sm border-2 text-secondary border-secondary px-1.5 h-8 rounded-sm bg-white">
                      {'\u00A0'}
                    </div>
                  </div>
                </div> */}

                {/* Zona CTE */}
                {/* <div className="flex flex-wrap items-center justify-end w-full gap-2">
                  <label
                    title={t('uw_simulator.viewDetail.cte_zone')}
                    className="text-primary font-notoBlack"
                    htmlFor="cte_zone"
                  >
                    {t('uw_simulator.viewDetail.cte_zone')}
                  </label>

                  <div className="flex flex-row items-center space-x-4">
                    <div className="w-[10.25rem] font-noto text-sm border-2 text-white border-primary bg-primary px-1.5 h-8 rounded-sm">
                      {'\u00A0'}
                    </div>
                  </div>
                </div> */}

                {/* Botón Descargar PDF */}
                {/* <div className="flex flex-row items-center justify-end w-full space-x-2">
                  <button
                    title={t('uw_simulator.viewDetail.download_pdf')}
                    type="button"
                    className="h-10 px-4 border-[3px] border-secondary rounded-[15px] font-notoExtraCondensedBlack text-primary transition-colors duration-150 outline-none bg-white enabled:hover:bg-secondary disabled:opacity-30 disabled:cursor-not-allowed"
                    disabled
                  >
                    {t('uw_simulator.viewDetail.download_pdf')}
                  </button>
                </div> */}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

import { useTranslation } from 'react-i18next'

const LanguageSwitcher = () => {
  const { i18n } = useTranslation()

  return (
    <div className="flex flex-row text-sm text-gray-700 divide-x-2 cursor-pointer divide-solid divide-primary">
      <div
        title="Cambiar a idioma español"
        onClick={() => i18n.changeLanguage('es')}
        className={`flex flex-row items-center space-x-1 font-notoBold text-sm md:text-base cursor-pointer pr-1 ${
          i18n.language === 'es' || i18n.language === 'es-ES'
            ? 'text-secondary'
            : 'text-gray-500'
        }`}
      >
        ES
      </div>

      <div
        title="Cambiar a idioma portugués"
        onClick={() => i18n.changeLanguage('pt')}
        className={`flex flex-row items-center space-x-1 font-notoBold text-sm md:text-base cursor-pointer pl-1 ${
          i18n.language === 'pt' || i18n.language === 'pt-PT'
            ? 'text-secondary'
            : 'text-gray-500'
        }`}
      >
        PT
      </div>
    </div>
  )
}

export default LanguageSwitcher

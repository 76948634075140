import PropTypes from 'prop-types'
import { createContext, useContext, useMemo, useState } from 'react'

// Crea un contexto para gestionar el estado del título de la página actual
export const TitleContext = createContext(null)

// Proveedor del contexto del título de la página actual. Envuelve los componentes hijos y proporciona las funciones para manejar el título de la página actual
export const TitleProvider = ({ children }) => {
  // Estado para almacenar el título de la página actual
  const [title, setTitle] = useState({})

  const contextValue = useMemo(() => ({ title, setTitle }), [title])

  return (
    // Proporciona el estado y las funciones del título de la página actual a los componentes hijos
    <TitleContext.Provider value={contextValue}>
      {children}
    </TitleContext.Provider>
  )
}

// Hook personalizado para usar el contexto del título de la página actual de forma más sencilla
export const useTitle = () => useContext(TitleContext)

TitleProvider.propTypes = {
  children: PropTypes.any
}

import PropTypes from 'prop-types'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'

// Crea un contexto para gestionar el estado del usuario actual
export const UserContext = createContext(null)

// Proveedor del contexto del usuario actual. Envuelve los componentes hijos y proporciona las funciones para manejar el usuario actual
export const UserProvider = ({ children }) => {
  // Estado para el usuario actual, inicializado desde localStorage si existe
  const initialUser = useMemo(() => {
    const storedUser = localStorage.getItem('user')
    return storedUser && storedUser !== 'undefined'
      ? JSON.parse(storedUser)
      : null
  }, [])

  const [user, setUser] = useState(initialUser)

  // Guarda el estado del usuario en localStorage cada vez que este cambia
  useEffect(() => {
    if (user !== null) {
      const storedUser = localStorage.getItem('user')
      if (storedUser !== JSON.stringify(user)) {
        localStorage.setItem('user', JSON.stringify(user))
      }
    } else {
      localStorage.clear()
    }
  }, [user])

  const contextValue = useMemo(() => ({ user, setUser }), [user])

  return (
    // Proporciona el estado y las funciones del usuario actual a los componentes hijos
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  )
}

// Hook personalizado para usar el contexto del usuario actual de forma más sencilla
export const useUser = () => useContext(UserContext)

UserProvider.propTypes = {
  children: PropTypes.any
}
